import { useGetFuelConsumptionQuery } from '@modules/results/api/summary.api';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';
import { coverCropsGraphTheme } from '@modules/results/utils/theme/coverCropsGraphTheme';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { FuelConsumptionResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';

export const useFuelConsumptionGraphLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const fuelConsumptionGraphRef = useRef(null);

    const {
        data: fuelConsumptionData,
        isLoading: isLoadingfuelConsumptionData,
        isFetching: isFetchingfuelConsumptionData,
    } = useGetFuelConsumptionQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: FuelConsumptionResponseT | undefined } = useToggleHistoryYears({
        data: fuelConsumptionData,
        showHistoryYears,
    });

    useEffect(() => {
        if (data && fuelConsumptionGraphRef.current) {
            const fuelConsumptionGraph = echarts.init(fuelConsumptionGraphRef.current, coverCropsGraphTheme, {
                renderer: 'svg',
            });

            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            Object.keys(data).forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                formattedData[translatedHarvestYear] = {
                    [t('results.summary.fuel-consumption-graph.legend.label')]: {
                        value: data[harvestYear].energy_per_ha,
                    },
                };
            });

            const options = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.BAR_LINE,
                yLabel: t('results.summary.fuel-consumption-graph.ylabel'),
                tooltipTitle: t('results.summary.fuel-consumption-graph.tooltip.title'),
                tooltipUnit: t('results.summary.fuel-consumption-graph.tooltip.unit'),
                showTooltipPercentage: false,
                showTotal: false,
            });

            fuelConsumptionGraph.setOption(options);

            return () => {
                fuelConsumptionGraph.dispose();
            };
        }
    }, [data, t]);

    return {
        t,
        fuelConsumptionGraphRef,
        isLoading: isLoadingfuelConsumptionData || isFetchingfuelConsumptionData,
    };
};
