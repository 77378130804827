import { useGetMineralFertilisersQuery, useGetNitrogenPerCropQuery } from '@modules/results/api/summary.api';
import * as echarts from 'echarts';
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import {
    FarmSeasonMineralFertilisersResponseT,
    FarmSeasonMineralFertilisersT,
    FarmSeasonNitrogenPerCrop,
    FarmSeasonNitrogenPerCropResponseT,
} from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';
import { cropRotationGraphTheme } from '@modules/results/utils/theme/cropRotationGraphTheme';
import { mineralFertilisationGraphTheme } from '@modules/results/utils/theme/mineralFertilisationGraphTheme';
import { adjustChartLegendHeight } from '@modules/results/utils/adjustChartLegendHeight';

export const useMineralFertilisationGraph = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const [switchView, setSwitchView] = useState(false);
    const { currentSeasonId } = useCurrentSeasonId();
    const {
        data: mineralFertilisationData,
        isLoading,
        isFetching,
        isError: isMineralFertilisationError,
    } = useGetMineralFertilisersQuery({
        farmSeasonId: currentSeasonId,
    });

    const {
        data: nitrogenPerCropData,
        isLoading: isLoadingNitrogenPerCrop,
        isFetching: isFetchingNitrogenPerCrop,
        isError: isNitrogenPerCropError,
    } = useGetNitrogenPerCropQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data: toggledMineralFertilisationData }: { data: FarmSeasonMineralFertilisersResponseT | undefined } =
        useToggleHistoryYears({
            data: mineralFertilisationData,
            showHistoryYears,
        });

    const { data: toggledNitrogenPerCropData }: { data: FarmSeasonNitrogenPerCropResponseT | undefined } =
        useToggleHistoryYears({
            data: nitrogenPerCropData,
            showHistoryYears,
        });

    const handleSwitchView = () => {
        setSwitchView((prev) => !prev);
    };

    const mineralFertilisationRef = useRef(null);
    const nitrogenPerCropRef = useRef(null);

    useEffect(() => {
        if (toggledMineralFertilisationData && mineralFertilisationRef.current && !switchView) {
            const mineralFertilisation = echarts.init(mineralFertilisationRef.current, mineralFertilisationGraphTheme, {
                renderer: 'svg',
            });

            const years = Object.keys(toggledMineralFertilisationData).sort();
            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};

            years.forEach((year) => {
                const translatedYear = t('constants.harvest-year', { harvestYear: year });
                formattedData[translatedYear] = {};
            });

            const allFertilizers = new Set<string>();
            years.forEach((year) => {
                const yearData = toggledMineralFertilisationData[year];
                if (Array.isArray(yearData)) {
                    yearData.forEach((fert) => allFertilizers.add(fert.slug));
                }
            });

            const fertilizerValues: Record<string, number> = {};
            allFertilizers.forEach((slug) => {
                fertilizerValues[slug] = 0;
                years.forEach((year) => {
                    const yearData = toggledMineralFertilisationData[year];
                    if (Array.isArray(yearData)) {
                        const fert = yearData.find((f) => f.slug === slug);
                        if (fert) {
                            fertilizerValues[slug] += fert.value;
                        }
                    }
                });
            });

            const sortedFertilizers = Array.from(allFertilizers).sort(
                (a, b) => fertilizerValues[b] - fertilizerValues[a],
            );

            years.forEach((year) => {
                const translatedYear = t('constants.harvest-year', { harvestYear: year });
                const yearData = toggledMineralFertilisationData[year];
                const yearMap: Record<string, FarmSeasonMineralFertilisersT> = {};

                if (Array.isArray(yearData)) {
                    yearData.forEach((fert) => {
                        yearMap[fert.slug] = fert;
                    });
                }

                sortedFertilizers.forEach((slug) => {
                    const translatedName = t(slug);
                    formattedData[translatedYear][translatedName] = {
                        value: yearMap[slug]?.value || 0,
                    };
                });
            });

            const graphOptions = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.MULTI_BAR,
                yLabel: t('results.summary.mineral-fertilisers-consommation-graph.ylabel'),
                tooltipTitle: t('results.summary.mineral-fertilisers-consommation-graph.tooltip.title'),
                tooltipUnit: t('results.summary.mineral-fertilisers-consommation-graph.tooltip.unit'),
            });
            mineralFertilisation.setOption(graphOptions);
            adjustChartLegendHeight(mineralFertilisation, 'mineral-fertilisation-graph');

            return () => {
                mineralFertilisation.dispose();
            };
        }
    }, [toggledMineralFertilisationData, switchView, t]);

    useEffect(() => {
        if (toggledNitrogenPerCropData && nitrogenPerCropRef.current && switchView) {
            const nitrogenPerCropChart = echarts.init(nitrogenPerCropRef.current, cropRotationGraphTheme, {
                renderer: 'svg',
            });
            const years = Object.keys(toggledNitrogenPerCropData).sort();
            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};

            years.forEach((year) => {
                const translatedYear = t('constants.harvest-year', { harvestYear: year });
                formattedData[translatedYear] = {};
            });

            const allCrops = new Set<string>();
            years.forEach((year) => {
                const yearData = toggledNitrogenPerCropData[year];
                if (Array.isArray(yearData)) {
                    yearData.forEach((crop) => allCrops.add(crop.slug));
                }
            });

            const cropValues: Record<string, number> = {};
            allCrops.forEach((slug) => {
                cropValues[slug] = 0;
                years.forEach((year) => {
                    const yearData = toggledNitrogenPerCropData[year];
                    if (Array.isArray(yearData)) {
                        const crop = yearData.find((c) => c.slug === slug);
                        if (crop) {
                            cropValues[slug] += crop.value;
                        }
                    }
                });
            });

            const sortedCrops = Array.from(allCrops).sort((a, b) => cropValues[b] - cropValues[a]);

            years.forEach((year) => {
                const translatedYear = t('constants.harvest-year', { harvestYear: year });
                const yearData = toggledNitrogenPerCropData[year];
                const yearMap: Record<string, FarmSeasonNitrogenPerCrop> = {};

                if (Array.isArray(yearData)) {
                    yearData.forEach((crop) => {
                        yearMap[crop.slug] = crop;
                    });
                }

                sortedCrops.forEach((slug) => {
                    const translatedName = t(slug);
                    formattedData[translatedYear][translatedName] = {
                        value: yearMap[slug]?.value || 0,
                        is_organic: yearMap[slug]?.is_organic || false,
                    };
                });
            });

            const graphOptions = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.STACKED_BAR,
                yLabel: t('results.summary.nitrogen-per-crop-graph.ylabel'),
                tooltipTitle: t('results.summary.nitrogen-per-crop-graph.tooltip.title'),
                tooltipUnit: t('results.summary.nitrogen-per-crop-graph.tooltip.unit'),
            });
            nitrogenPerCropChart.setOption(graphOptions);
            adjustChartLegendHeight(nitrogenPerCropChart, 'nitrogen-per-crop-graph');

            return () => {
                nitrogenPerCropChart.dispose();
            };
        }
    }, [mineralFertilisationData, toggledNitrogenPerCropData, switchView, t]);

    return {
        mineralFertilisationRef,
        nitrogenPerCropRef,
        isLoading: isLoading || isFetching || isLoadingNitrogenPerCrop || isFetchingNitrogenPerCrop,
        t,
        handleSwitchView,
        switchView,
        isMineralFertilisationError,
        isNitrogenPerCropError,
    };
};
