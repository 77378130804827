import { useTranslation } from 'react-i18next';
import { useMineralFertilisationTableStyles } from './MineralFertilisationTable.style';
import { useMineralFertilisationTableLogic } from './MineralFertilisationTable.logic';
import { MenuItem, Select, Table } from '@soil-capital/ui-kit/components';
import { TableSkeleton } from '../../newShared/TableSkeleton/TableSkeleton';

export const MineralFertilisationTable = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { t } = useTranslation();
    const { classes } = useMineralFertilisationTableStyles();
    const {
        isLoading,
        formattedFertilisersPerCropData,
        selectedHarvestYear,
        handleHarvestChange,
        availableHarvestYears,
    } = useMineralFertilisationTableLogic(showHistoryYears);

    if (formattedFertilisersPerCropData.headers.length === 0 && !isLoading) {
        return null;
    }

    return (
        <div className={classes.tableContainer}>
            {isLoading ? (
                <TableSkeleton />
            ) : (
                <>
                    <Select
                        value={selectedHarvestYear}
                        onChange={handleHarvestChange}
                        sx={{ width: '240px', maxHeight: '46px' }}
                    >
                        {availableHarvestYears.map((option) => (
                            <MenuItem key={option} value={option}>
                                {t(option)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Table
                        {...formattedFertilisersPerCropData}
                        seeLessLabel={t('constants.see-less')}
                        seeMoreLabel={t('constants.see-more')}
                        seeNextLabel={t('constants.see-next')}
                        stickyFirstColumn
                    />
                </>
            )}
        </div>
    );
};
