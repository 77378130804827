export type Item = number | { value: number | string; name: string };

export type basicBarGraphBuilderType = {
    data: { [category: string]: { [item: string]: Item } };
};

export type resultsBarGraphBuilderType = {
    data: { [category: string]: { [item: string]: Item } };
    tooltipTitle?: string;
    tooltipUnit?: string;
    multipleCropsCategories?: string[];
    multipleCropsCategoriesMessage?: string;
    animation?: boolean;
    withTooltip?: boolean;
};

export type summaryBarGraphBuilderType = {
    data: { [category: string]: { [item: string]: Item } };
    yTitle?: string;
    hasLegend?: boolean;
    hasTooltip?: boolean;
    tooltipTitle?: string;
    tooltipUnit?: string;
    tooltipTotal?: number;
};

export type pieChartDataT = {
    name: string;
    label: string;
    slug: string;
    total_area: number | string;
    value: number;
};
export type pieChartGraphBuilderType = {
    data: pieChartDataT[];
    hasLegend?: boolean;
    legendFormatter?: (name: string) => string;
    hasTooltip?: boolean;
    tooltipTitle?: string;
    tooltipUnit?: string;
    labelFormatter?: string;
    tooltipTotal?: number;
    chartId: string;
};

export type GraphDataItemT = { value: number; is_organic?: boolean };

export type GraphCategoryItemsT = {
    [item: string]: GraphDataItemT;
};

export type GraphData = {
    [category: string]: GraphCategoryItemsT;
};

export enum GraphTypeT {
    STACKED_BAR = 'stacked-bar',
    MULTI_BAR = 'multi-bar',
    BAR_LINE = 'bar-line',
}

export type GraphBuilderPropsT = {
    data: GraphData;
    type: GraphTypeT;
    yLabel?: string;
    tooltipTitle?: string;
    tooltipUnit?: string;
    tooltipLabel?: string;
    showTooltipPercentage?: boolean;
    showTotal?: boolean;
    tooltipTotal?: number;
    showSelectedXAxis?: boolean;
    showReferenceXAxis?: boolean;
};
