import { IconPlant } from '@soil-capital/ui-kit/icons';
import { useCoverCropsGraphLogic } from './CoverCropsGraph.logic';
import { useCoverCropsGraphsStyles } from './CoverCropsGraph.style';
import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';

export const CoverCropsGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { t, coverCropsGraphRef, isLoading, isError } = useCoverCropsGraphLogic(showHistoryYears);
    const { classes } = useCoverCropsGraphsStyles();

    return (
        <GraphContainer
            title={t('results.summary.cover-crops-graph.title')}
            titleIcon={<IconPlant fontSize="large" className={classes.icon} />}
            isLoading={isLoading}
            type={GraphTypeT.STACKED_BAR}
            hasNoData={isError}
        >
            <div ref={coverCropsGraphRef} className={classes.graphContainer}></div>
        </GraphContainer>
    );
};
