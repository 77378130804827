import { useGetIrrigationDataQuery } from '@modules/results/api/summary.api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';
import { IrrigationResponseT } from '@modules/results/api/summary.types';

export const useIrrigationTableLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const [selectedHarvestYear, setSelectedHarvestYear] = useState<string | null>(null);

    const {
        data: irrigationData,
        isLoading: isLoadingIrrigationData,
        isFetching: isFetchingIrrigationData,
    } = useGetIrrigationDataQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: IrrigationResponseT | undefined } = useToggleHistoryYears({
        data: irrigationData,
        showHistoryYears,
    });

    const harvestOptions = useMemo(() => {
        if (!data) {
            return [];
        }
        return Object.keys(data)
            .filter((year) => {
                const yearData = data[year];
                return Array.isArray(yearData) && yearData.length > 0;
            })
            .sort();
    }, [data]);

    const handleHarvestChange = useCallback((event: SelectChangeEvent<unknown>) => {
        setSelectedHarvestYear((event.target as HTMLSelectElement).value);
    }, []);

    useEffect(() => {
        if (data && !selectedHarvestYear) {
            setSelectedHarvestYear(harvestOptions[harvestOptions.length - 1]);
        }
    }, [data, harvestOptions, selectedHarvestYear]);

    const formattedIrrigationPerCrop = useMemo(() => {
        if (data && selectedHarvestYear && data[selectedHarvestYear]) {
            const headers = [
                t('constants.crop'),
                t('results.summary.irrigation.ylabel.percentage_of_crop_surface'),
                t('constants.unit.ha'),
                t('constants.unit.milimeters-ha'),
            ];
            const tableData = data[selectedHarvestYear].map((data) => {
                return [t(`${data.slug}`), `${data.percentage_irrigated} %`, data.irrigated_area, data.mm_per_ha];
            });
            return {
                headers,
                data: tableData,
            };
        } else {
            return {
                headers: [],
                data: [[]],
            };
        }
    }, [data, selectedHarvestYear, t]);

    return {
        t,
        harvestOptions,
        selectedHarvestYear,
        handleHarvestChange,
        formattedIrrigationPerCrop,
        isLoading: isLoadingIrrigationData || isFetchingIrrigationData,
    };
};
