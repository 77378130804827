import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCarbonResultsEarningPerCropStyles = makeStyles()((theme) => ({
    padding: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    resultsContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: theme.spacing(3),
        gap: theme.spacing(3),
    },
    graphContainer: {
        width: '100%',
        height: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    toggle: { justifySelf: 'flex-end' },
    resultsContainerBlured: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        filter: 'blur(9px)',
    },
    teaserContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    teaserSubContainer: {
        padding: theme.spacing(6),
        zIndex: 30,
        width: '100%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    teaserTextContainer: {
        zIndex: 30,
        width: '100%',
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        paddingRight: theme.spacing(10),
    },
    blurredGraphContainer: {
        padding: theme.spacing(2),
        minWidth: '45%',
        flexGrow: 1,
        height: '100%',
    },
    arrowContainer: { display: 'flex', justifyContent: 'end', width: '100%' },
    icon: { color: theme.palette.darkScale[700] },
}));
