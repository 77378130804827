import api from '@shared/api';
import {
    CoverCropsResponseT,
    FarmSeasonNitrogenPerCropResponseT,
    CropRotationResponseT,
    FertilisersPerCropResponseT,
    FarmSeasonOrganicFertiliserResponseT,
    TillagePracticeResponseT,
    FarmSeasonOrganicFertiliserPerCropResponseT,
    ResidueManagementResponseT,
    FuelConsumptionResponseT,
    livestockResponseT,
    LegumesResponseT,
    chalkResponseT,
    IrrigationResponseT,
    FarmSeasonMineralFertilisersResponseT,
    MineralFertilisationNutrientsResponseT,
} from './summary.types';

export const summaryApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCropRotation: builder.query<CropRotationResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/crop-rotation`,
                method: 'GET',
            }),
        }),
        getNitrogenPerCrop: builder.query<FarmSeasonNitrogenPerCropResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/mineral-fertilisation/nitrogen-per-crop`,
                method: 'GET',
            }),
        }),
        getMineralFertilisers: builder.query<FarmSeasonMineralFertilisersResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/mineral-fertilisation/mineral-fertilisers`,
                method: 'GET',
            }),
        }),
        getOrganicFertiliser: builder.query<FarmSeasonOrganicFertiliserResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/organic-fertilisation/organic-fertiliser`,
                method: 'GET',
            }),
        }),
        getMineralFertilisationNutrients: builder.query<
            MineralFertilisationNutrientsResponseT,
            { farmSeasonId: number }
        >({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/mineral-fertilisation/nutrients`,
                method: 'GET',
            }),
        }),
        getMineralFertilisersPerCrop: builder.query<FertilisersPerCropResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/mineral-fertilisation/fertilisers-per-crop`,
                method: 'GET',
            }),
        }),
        getTillagePractice: builder.query<TillagePracticeResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/tillage-practices`,
                method: 'GET',
            }),
        }),
        getCoverCrops: builder.query<CoverCropsResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/cover-crops`,
                method: 'GET',
            }),
        }),
        getOrganicFertiliserPerCrop: builder.query<
            FarmSeasonOrganicFertiliserPerCropResponseT,
            { farmSeasonId: number }
        >({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/organic-fertilisation/organic-fertiliser-per-crop`,
                method: 'GET',
            }),
        }),
        getResidueManagement: builder.query<ResidueManagementResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/residue-management`,
                method: 'GET',
            }),
        }),
        getFuelConsumption: builder.query<FuelConsumptionResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/fuel-consumption`,
                method: 'GET',
            }),
        }),
        getLegumes: builder.query<LegumesResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/legumes`,
                method: 'GET',
            }),
        }),
        getLivestockData: builder.query<livestockResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/livestock`,
                method: 'GET',
            }),
        }),
        getChalk: builder.query<chalkResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/chalk`,
                method: 'GET',
            }),
        }),
        getIrrigationData: builder.query<IrrigationResponseT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/summary/irrigation`,
                method: 'GET',
            }),
        }),
    }),
});

export const useGetCropRotationQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetCropRotationQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetChalkQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetChalkQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetLegumesQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetLegumesQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetLivestockDataQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetLivestockDataQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetFuelConsumptionQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetFuelConsumptionQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetResidueManagementQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetResidueManagementQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetOrganicFertiliserPerCropQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetOrganicFertiliserPerCropQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetCoverCropsQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetCoverCropsQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetTillagePracticeQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetTillagePracticeQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetOrganicFertiliserQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetOrganicFertiliserQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetNitrogenPerCropQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetNitrogenPerCropQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetMineralFertilisersQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetMineralFertilisersQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetMineralFertilisersPerCropQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetMineralFertilisersPerCropQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetMineralFertilisationNutrientsQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetMineralFertilisationNutrientsQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};

export const useGetIrrigationDataQuery = (params: { farmSeasonId: number | null }) => {
    return summaryApi.useGetIrrigationDataQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { farmSeasonId: params.farmSeasonId! },
        { skip: !params.farmSeasonId },
    );
};
