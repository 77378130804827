import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { useMineralFertilisationGraph } from './MineralFertilisationGraph.logic';
import { useMineralFertilisationGraphStyles } from './MineralFertilisationGraph.style';
import { Toggle } from '@soil-capital/ui-kit/components';

export const MineralFertilisationGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const {
        mineralFertilisationRef,
        nitrogenPerCropRef,
        switchView,
        isLoading,
        t,
        handleSwitchView,
        isMineralFertilisationError,
        isNitrogenPerCropError,
    } = useMineralFertilisationGraph(showHistoryYears);
    const { classes } = useMineralFertilisationGraphStyles();

    return (
        <GraphContainer
            title={
                switchView
                    ? t('results.summary.nitrogen-per-crop-graph.title')
                    : t('results.summary.mineral-fertilisers-consommation-graph.title')
            }
            isLoading={isLoading}
            hasBorders
            type={switchView ? GraphTypeT.STACKED_BAR : GraphTypeT.MULTI_BAR}
            titleEnd={
                <Toggle
                    labelLeft={t('results.summary.mineral-fertilisers-consommation-graph.per-product')}
                    labelRight={t('results.summary.mineral-fertilisers-consommation-graph.per-crop')}
                    isChecked={switchView}
                    onChange={handleSwitchView}
                />
            }
            hasNoData={switchView ? isNitrogenPerCropError : isMineralFertilisationError}
        >
            {!switchView && (
                <div ref={mineralFertilisationRef} className={classes.container} id="mineral-fertilisation-graph" />
            )}
            {switchView && <div ref={nitrogenPerCropRef} className={classes.container} id="nitrogen-per-crop-graph" />}
        </GraphContainer>
    );
};
