import { GraphContainer } from '@modules/results/components/newShared/GraphContainer/GraphContainer';
import { IconCropRotation } from '@soil-capital/ui-kit/icons';
import { useLegumeGraphLogic } from './LegumeGraph.logic';
import { useLegumeGraphStyles } from './LegumeGraph.style';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';

export const LegumeGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { legumesGraphRef, t, isLoading, isError } = useLegumeGraphLogic(showHistoryYears);
    const { classes } = useLegumeGraphStyles();

    return (
        <GraphContainer
            title={t('results.summary.legumes-graph.title')}
            type={GraphTypeT.BAR_LINE}
            isLoading={isLoading}
            titleIcon={<IconCropRotation fontSize="large" className={classes.icon} />}
            hasNoData={isError}
        >
            <div ref={legumesGraphRef} className={classes.graphContainer}></div>
        </GraphContainer>
    );
};
