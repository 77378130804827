import { PageWithHeader } from '@shared/components/PageWithHeader/PageWithHeader';
import { Button } from '@soil-capital/ui-kit/components';
import {
    IconArrowEmission,
    IconArrowLeft,
    IconArrowRight,
    IconArrowSequestration,
    IconEye,
    IconEyeOff,
    IconInfo,
    IconIrrigation,
    IconMineralFertilisation,
    IconOrganicFertilisation,
} from '@soil-capital/ui-kit/icons';
import { useResultsSummaryStyle } from './ResultsSummary.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import { useResultsSummaryLogic } from './useResultsSummary.logic';
import { SummarySkeleton } from '@modules/results/components/newShared/SummarySkeleton/SummarySkeleton';
import { LegumeGraph } from '@modules/results/components/NewResultsSummary/LegumeGraph/LegumeGraph';
import { ResidueManagementGraph } from '@modules/results/components/NewResultsSummary/ResidueManagementGraph/ResidueManagementGraph';
import { OrganicFertilisationGraph } from '@modules/results/components/NewResultsSummary/OrganicFertilisationGraph/OrganicFertilisationGraph';
import { CropRotationGraph } from '@modules/results/components/NewResultsSummary/CropRotationGraph/CropRotationGraph';
import { CoverCropsGraph } from '@modules/results/components/NewResultsSummary/CoverCropsGraph/CoverCropsGraph';
import { TillagePracticeGraph } from '@modules/results/components/NewResultsSummary/TillagePracticeGraph/TillagePracticeGraph';
import { IrrigationGraph } from '@modules/results/components/NewResultsSummary/IrrigationGraph/IrrigationGraph';
import { ChalkGraph } from '@modules/results/components/NewResultsSummary/ChalkGraph/ChalkGraph';
import { LivestockGraph } from '@modules/results/components/NewResultsSummary/LivestockGraph/LivestockGraph';
import { MineralFertilisationGraph } from '@modules/results/components/NewResultsSummary/MineralFertilisationGraph/MineralFertilisationGraph';
import { MineralFertilisationNutrientsGraph } from '@modules/results/components/NewResultsSummary/MineralFertilisationNutrientsGraph/MineralFertilisationNutrientsGraphs';
import { IrrigationTable } from '@modules/results/components/NewResultsSummary/IrrigationTable/IrrigationTable';
import { OrganicFertilisersTable } from '@modules/results/components/NewResultsSummary/OrganicFertilisersTable/OrganicFertilisersTable';
import { SectionContainer } from '@modules/results/components/newShared/SectionContainer/SectionContainer';
import { MineralFertilisationTable } from '@modules/results/components/NewResultsSummary/MineralFertilisationTable/MineralFertilisationTable';
import { FuelConsumptionGraph } from '@modules/results/components/NewResultsSummary/FuelConsumptionGraph/FuelConsumptionGraph';

export const ResultsSummary = () => {
    const { t } = useTranslation();
    const { classes } = useResultsSummaryStyle();
    const {
        isLoading,
        handleBackClicked,
        handleContinueEncoding,
        handleStartEncoding,
        isEncodingDone,
        isEncodingStarted,
        showHistoryYears,
        setShowHistoryYears,
    } = useResultsSummaryLogic();

    if (isLoading) {
        return <SummarySkeleton />;
    }

    return (
        <PageWithHeader
            header={
                <div className={classes.flexBox}>
                    <Button onClick={handleBackClicked} size="medium" startIcon={<IconArrowLeft />} variant="text">
                        {t('constants.back')}
                    </Button>
                    <Button
                        variant="text"
                        startIcon={showHistoryYears ? <IconEye /> : <IconEyeOff />}
                        onClick={() => setShowHistoryYears(!showHistoryYears)}
                    >
                        {showHistoryYears ? t('results.summary.show-history') : t('results.summary.hide-history')}
                    </Button>
                </div>
            }
            content={
                isEncodingDone ? (
                    <>
                        <div className={classes.title}>
                            <Typography variant="h1">{t('results.summary.title')}</Typography>
                            <Typography variant="body2">
                                <Trans i18nKey="results.summary.subtitle" variant="body2" />
                            </Typography>
                        </div>
                        <div className={classes.cropRotationGraphContainer}>
                            <CropRotationGraph showHistoryYears={showHistoryYears} />
                        </div>
                        <div className={classes.textContainer}>
                            <Typography variant="h2">{t('results.summary.description-title')}</Typography>
                            <Typography variant="body2">
                                <Trans i18nKey="results.summary.description-subtitle" variant="body2" />
                            </Typography>
                            <div className={classes.commentContainer}>
                                <IconInfo />
                                <Typography variant="body2">
                                    <Trans i18nKey="results.summary.description-comment" variant="body2" />
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.sequestration}>
                            <div className={classes.sectionTitle}>
                                <IconArrowSequestration color="success" fontSize="large" />
                                <Typography variant="h3" sx={{ whiteSpace: 'nowrap' }}>
                                    {t('results.summary.sequestration.title')}
                                </Typography>
                                <div className={classes.horizontalBarSequestration}></div>
                            </div>
                            <SectionContainer
                                title={t('results.summary.organic-fertilisation.title')}
                                titleIcon={<IconOrganicFertilisation fontSize="large" className={classes.icon} />}
                            >
                                <OrganicFertilisationGraph showHistoryYears={showHistoryYears} />
                                <OrganicFertilisersTable showHistoryYears={showHistoryYears} />
                            </SectionContainer>
                            <CoverCropsGraph showHistoryYears={showHistoryYears} />
                            <ResidueManagementGraph showHistoryYears={showHistoryYears} />
                            <LegumeGraph showHistoryYears={showHistoryYears} />
                        </div>
                        <div className={classes.emission}>
                            <div className={classes.sectionTitle}>
                                <IconArrowEmission color="error" fontSize="large" />
                                <Typography variant="h3" sx={{ whiteSpace: 'nowrap' }}>
                                    {t('results.summary.emissions.title')}
                                </Typography>
                                <div className={classes.horizontalBarEmission}></div>
                            </div>
                            <SectionContainer
                                title={t('results.summary.mineral-fertilisation.title')}
                                titleIcon={<IconMineralFertilisation fontSize="large" className={classes.icon} />}
                            >
                                <MineralFertilisationGraph showHistoryYears={showHistoryYears} />
                                <MineralFertilisationNutrientsGraph showHistoryYears={showHistoryYears} />
                                <MineralFertilisationTable showHistoryYears={showHistoryYears} />
                            </SectionContainer>
                            <TillagePracticeGraph showHistoryYears={showHistoryYears} />
                            <FuelConsumptionGraph showHistoryYears={showHistoryYears} />
                            <ChalkGraph showHistoryYears={showHistoryYears} />
                            <LivestockGraph showHistoryYears={showHistoryYears} />
                            <SectionContainer
                                title={t('results.summary.irrigation.title')}
                                titleIcon={<IconIrrigation fontSize="large" className={classes.icon} />}
                            >
                                <IrrigationGraph showHistoryYears={showHistoryYears} />
                                <IrrigationTable showHistoryYears={showHistoryYears} />
                            </SectionContainer>
                        </div>
                    </>
                ) : (
                    <div className={classes.container}>
                        <img className={classes.image} src="/assets/images/no_data.svg" />
                        <Typography>{t('results.summary.encoding-pending.title')}</Typography>
                        <Typography variant="body2" className={classes.text}>
                            {isEncodingStarted
                                ? t('results.summary.encoding-pending.ongoing')
                                : t('results.summary.encoding-pending.start')}
                        </Typography>
                        <Button
                            variant="contained"
                            endIcon={<IconArrowRight />}
                            onClick={isEncodingStarted ? handleContinueEncoding : handleStartEncoding}
                        >
                            {isEncodingStarted
                                ? t('results.summary.encoding-pending.ongoing.cta')
                                : t('results.summary.encoding-pending.start.cta')}
                        </Button>
                    </div>
                )
            }
            contentClasses={classes.content}
        />
    );
};
