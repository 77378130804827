import { makeStyles } from '@soil-capital/ui-kit/style';

export const useIrrigationTableStyles = makeStyles()((theme) => ({
    tableContainer: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: theme.spacing(2),
    },
}));
