import { IconSearchRefraction } from '@soil-capital/ui-kit/icons';
import { Toggle } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { carbonUnits } from '@modules/results/api/results.types';
import { useCarbonResultsEarningPerCropStyles } from './useCarbonResultsEarningPerCrop.style';
import { useCarbonResultsEarningPerCropGraphLogic } from './useCarbonResultsEarningPerCropGraphLogic';
import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';

export const CarbonResultsEarningPerCropGraph = () => {
    const { t } = useTranslation();
    const { classes } = useCarbonResultsEarningPerCropStyles();
    const { carbonResultsPerCropGraphRef, setSelectedUnit, selectedUnit, isLoading } =
        useCarbonResultsEarningPerCropGraphLogic();

    return (
        <GraphContainer
            hasBorders={false}
            titleIcon={<IconSearchRefraction fontSize="medium" className={classes.icon} />}
            title={t('results.earning.crop-storage-balance.title')}
            isLoading={isLoading}
            titleEnd={
                <Toggle
                    labelLeft={t('constants.TCO2-ha')}
                    labelRight={t('constants.total-TCO2')}
                    isChecked={selectedUnit === carbonUnits.TCO2}
                    onChange={() => {
                        setSelectedUnit((prev) => (prev === carbonUnits.TCO2 ? carbonUnits.TCO2HA : carbonUnits.TCO2));
                    }}
                    className={classes.toggle}
                />
            }
        >
            <div className={classes.resultsContainer}>
                <div ref={carbonResultsPerCropGraphRef} className={classes.graphContainer}></div>
            </div>
        </GraphContainer>
    );
};
