import { Typography } from '@soil-capital/ui-kit/material-core';
import { ReactNode } from 'react';
import { useGraphContainerStyles } from './useGraphContainer.style';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { GraphSkeleton } from '../GraphSkeleton/GraphSkeleton';
import { NoDataFound } from '../NoDataFound/NoDataFound';

export const GraphContainer = ({
    title,
    titleIcon,
    switchViewSelect,
    children,
    isLoading = false,
    type,
    className,
    hasNoData,
    hasBorders = false,
    titleEnd,
}: {
    title?: string;
    titleIcon?: ReactNode;
    switchViewSelect?: ReactNode;
    children: ReactNode;
    isLoading?: boolean;
    type?: GraphTypeT;
    className?: string;
    hasNoData?: boolean;
    hasBorders?: boolean;
    titleEnd?: ReactNode;
}) => {
    const { classes, cx } = useGraphContainerStyles({ isLoading, hasBorders });
    return (
        <div className={cx(classes.container, className)}>
            <div className={classes.title}>
                {titleIcon && titleIcon}
                {title && <Typography variant="h3">{title}</Typography>}
                <div className={classes.titleEnd}>
                    {titleEnd ?? titleEnd}
                    {switchViewSelect && switchViewSelect}
                </div>
            </div>
            {isLoading && <GraphSkeleton type={type} />}
            <div className={classes.children}>{hasNoData ? <NoDataFound /> : <>{children}</>}</div>
        </div>
    );
};
