import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSectionContainerStyles = makeStyles()((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'flex-start',
        borderRadius: theme.shape.borderRadiusM,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 0px 32px 8px rgba(50, 63, 69, 0.08)',
        borderColor: theme.palette.darkScale[300],
        gap: theme.spacing(3),
    },
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(2),
    },
    emissionIcon: {
        color: theme.palette.error.light,
    },
    children: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'flex-start',
        borderColor: theme.palette.darkScale[300],
        gap: theme.spacing(3),
    },
}));
