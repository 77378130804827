import { TableSkeleton } from '../../newShared/TableSkeleton/TableSkeleton';
import { useOrganicFertilisersTableLogic } from './OrganicFertilisersTable.logic';
import { useOrganicFertilisersTableStyles } from './OrganicFertilisersTable.style';
import { MenuItem, Select, Table } from '@soil-capital/ui-kit/components';

export const OrganicFertilisersTable = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const {
        t,
        fertiliserSlugs,
        formattedTableData,
        harvestOptions,
        selectedHarvestYear,
        handleHarvestChange,
        isLoading,
    } = useOrganicFertilisersTableLogic(showHistoryYears);
    const { classes } = useOrganicFertilisersTableStyles();

    if (formattedTableData.length === 0 && !isLoading) {
        return null;
    }

    return (
        <div className={classes.tableContainer}>
            {isLoading ? (
                <TableSkeleton />
            ) : (
                <>
                    <Select
                        value={selectedHarvestYear}
                        onChange={handleHarvestChange}
                        sx={{ width: '240px', maxHeight: '46px' }}
                    >
                        {harvestOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {t(option)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Table
                        headers={fertiliserSlugs}
                        data={formattedTableData}
                        seeLessLabel={t('constants.see-less')}
                        seeMoreLabel={t('constants.see-more')}
                        seeNextLabel={t('constants.see-next')}
                        stickyFirstColumn
                    />
                </>
            )}
        </div>
    );
};
