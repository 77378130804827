import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSwitchViewStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    select: {
        display: 'flex',
    },
}));
