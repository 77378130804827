import { Quote } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { CarbonResultsGraph } from '../CarbonResultsGraph/CarbonResultsGraph';
import { useTranslation } from 'react-i18next';
import { useResultsEarningContentPageStyle } from './ResultsEarningContentPage.style';
import { FarmSeasonResultsPerCropV3T, FarmSeasonResultsV3T } from '@modules/results/api/results.types';
import { ResultsFaq } from '../ResultsFaq/ResultsFaq';
import { CarbonResultsPerCropGraph } from '../CarbonResultsPerCropGraph/CarbonResultsPerCropGraph';
import { useRef, useState } from 'react';
import { getSeasonNameByYear } from '@shared/utils/getSeasonNameByYear';
import { CarbonResultsEarningPerCropGraph } from '../CarbonResultsEarningPerCropGraph/CarbonResultsEarningPerCropGraph';

export type ResultsEarningContentPageT = {
    results: FarmSeasonResultsV3T;
    harvestYear: number;
    resultsPerCrop?: FarmSeasonResultsPerCropV3T;
};

export const ResultsEarningContentPage = ({ results, resultsPerCrop }: ResultsEarningContentPageT) => {
    const { t } = useTranslation();
    const { classes } = useResultsEarningContentPageStyle();
    const [openFaq, setOpenFaq] = useState(false);
    const faqRef = useRef<HTMLDivElement | null>(null);
    const isEmitter = results.ghg_balance > 0;
    const historyYear2 = getSeasonNameByYear(-2);
    const historyYear1 = getSeasonNameByYear(-1);
    const baselineYear = getSeasonNameByYear(0);
    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">{t('results.earning.title')}</Typography>
            </div>
            <div className={classes.marginBottom}>
                <Quote
                    author={{
                        avatar: '/assets/images/agro-max-avatar.jpeg',
                        name: 'Max,',
                        role: t('results.introduction.quote.author.role'),
                    }}
                >
                    <Typography sx={{ mb: 1 }} variant="h3">
                        {t('results.earning.quote.title', {
                            historyYear2,
                            historyYear1,
                            baselineYear,
                        })}
                    </Typography>
                    {isEmitter
                        ? t('results.earning.quote.content.emitter', {
                              historyYear2,
                              historyYear1,
                              baselineYear,
                          })
                        : t('results.earning.quote.content.storer', {
                              historyYear2,
                              historyYear1,
                              baselineYear,
                          })}
                </Quote>
            </div>
            <CarbonResultsGraph results={results} faqRef={faqRef} setOpenFaq={setOpenFaq} />
            <CarbonResultsPerCropGraph resultsPerCrop={resultsPerCrop} />
            <CarbonResultsEarningPerCropGraph />
            <ResultsFaq ref={faqRef} openFaq={openFaq} onClick={() => setOpenFaq(!openFaq)} isEmitter={isEmitter} />
        </>
    );
};
