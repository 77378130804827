import { useGetOrganicFertiliserPerCropQuery } from '@modules/results/api/summary.api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { FarmSeasonOrganicFertiliserPerCropResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';

export const useOrganicFertilisersTableLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const [selectedHarvestYear, setSelectedHarvestYear] = useState<string | null>(null);

    const {
        data: organicFertiliserTableData,
        isLoading: isLoadingOrganicFertiliserPerCrop,
        isFetching: isFetchingOrganicFertiliserPerCrop,
    } = useGetOrganicFertiliserPerCropQuery({ farmSeasonId: currentSeasonId });

    const { data }: { data: FarmSeasonOrganicFertiliserPerCropResponseT | undefined } = useToggleHistoryYears({
        data: organicFertiliserTableData,
        showHistoryYears,
    });

    const fertiliserSlugs = useMemo(() => {
        if (!data || !selectedHarvestYear) {
            return [];
        }
        return [
            t('constants.crop'),
            ...(data[selectedHarvestYear]?.organic_fertiliser_list.map((fertiliser) => t(fertiliser.slug)) || []),
            t('constants.total'),
        ];
    }, [data, selectedHarvestYear, t]);

    useEffect(() => {
        if (data && !selectedHarvestYear) {
            const harvestYears = Object.keys(data);
            setSelectedHarvestYear(harvestYears[harvestYears.length - 1]);
        }
    }, [data, selectedHarvestYear]);

    const harvestOptions = useMemo(() => {
        if (!data) {
            return [];
        }
        return Object.keys(data)
            .filter((year) => {
                const yearData = data[year].organic_fertiliser_per_crop;
                return Array.isArray(yearData) && yearData.length > 0;
            })
            .sort();
    }, [data]);

    const handleHarvestChange = useCallback((event: SelectChangeEvent<unknown>) => {
        setSelectedHarvestYear((event.target as HTMLSelectElement).value);
    }, []);

    const formattedTableData = useMemo(() => {
        if (!data || !selectedHarvestYear || !fertiliserSlugs || fertiliserSlugs.length === 0) {
            return [];
        }
        const fertiliserList = data[selectedHarvestYear]?.organic_fertiliser_list;
        const cropList = data[selectedHarvestYear].crop_list;
        const fertlisersPerCrop = data[selectedHarvestYear].organic_fertiliser_per_crop;
        return cropList.map((crop) => {
            const cropFertilisers = fertlisersPerCrop.filter((fert) => fert.crop_id === crop.id);
            const totalQuantity = cropFertilisers
                .reduce((acc, fert) => acc + parseFloat(fert.total_quantity), 0)
                .toFixed(2)
                .replace(/\.?0+$/, '');

            return [
                t(crop.slug),
                ...fertiliserList.map(
                    (fertiliser) =>
                        `${
                            cropFertilisers.find((fert) => fert.fertiliser_slug === fertiliser.slug)?.quantity || '0'
                        } t/${t('constants.hectare-unit')}`,
                ),
                `${totalQuantity} t/${t('constants.hectare-unit')}`,
            ];
        });
    }, [fertiliserSlugs, data, selectedHarvestYear, t]);

    return {
        t,
        formattedTableData,
        fertiliserSlugs,
        selectedHarvestYear,
        harvestOptions,
        handleHarvestChange,
        isLoading: isLoadingOrganicFertiliserPerCrop || isFetchingOrganicFertiliserPerCrop,
    };
};
