import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { useMineralFertilisationNutrientsGraph } from './MineralFertilisationNutrientsGraph.logic';
import { useMineralFertilisationNutrientsGraphStyles } from './MineralFertilisationNutrientsGraph.style';

export const MineralFertilisationNutrientsGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { mineralFertilisationRef, isLoading, t, isError } = useMineralFertilisationNutrientsGraph(showHistoryYears);
    const { classes } = useMineralFertilisationNutrientsGraphStyles();

    return (
        <GraphContainer
            title={t('results.summary.mineral-fertilisation-nutrients-graph.title')}
            isLoading={isLoading}
            hasBorders
            type={GraphTypeT.MULTI_BAR}
            hasNoData={isError}
        >
            <div ref={mineralFertilisationRef} className={classes.container} />
        </GraphContainer>
    );
};
