import { Grid, Skeleton } from '@soil-capital/ui-kit/material-core';
import { useGraphSkeletonStyles } from './GraphSkeleton.style';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';

export const GraphSkeleton = ({ type = GraphTypeT.MULTI_BAR }: { type?: GraphTypeT }) => {
    const { classes, cx } = useGraphSkeletonStyles();
    if (type === GraphTypeT.STACKED_BAR) {
        return (
            <div className={classes.loadingContainerHorizontal}>
                <Skeleton className={classes.skeleton} width="3px" height="100%" />
                <div className={classes.loadingContainerVertical}>
                    <div className={classes.loadingBars}>
                        <div className={classes.stackedBar}>
                            <Skeleton className={classes.skeleton} width={55} height="50%" />
                            <Skeleton className={classes.skeleton} width={55} height="25%" />
                            <Skeleton className={classes.skeleton} width={55} height="15%" />
                        </div>
                        <div className={classes.stackedBar}>
                            <Skeleton className={classes.skeleton} width={55} height="50%" />
                            <Skeleton className={classes.skeleton} width={55} height="20%" />
                            <Skeleton className={classes.skeleton} width={55} height="10%" />
                        </div>
                        <div className={classes.stackedBar}>
                            <Skeleton className={classes.skeleton} width={55} height="30%" />
                            <Skeleton className={classes.skeleton} width={55} height="30%" />
                            <Skeleton className={classes.skeleton} width={55} height="5%" />
                        </div>
                        <div className={classes.stackedBar}>
                            <Skeleton className={classes.skeleton} width={55} height="40%" />
                            <Skeleton className={classes.skeleton} width={55} height="20%" />
                            <Skeleton className={classes.skeleton} width={55} height="15%" />
                        </div>
                        <div className={classes.stackedBar}>
                            <Skeleton className={classes.skeleton} width={55} height="40%" />
                            <Skeleton className={classes.skeleton} width={55} height="25%" />
                            <Skeleton className={classes.skeleton} width={55} height="15%" />
                        </div>
                        <div className={classes.stackedBar}>
                            <Skeleton className={classes.skeleton} width={55} height="40%" />
                            <Skeleton className={classes.skeleton} width={55} height="10%" />
                            <Skeleton className={classes.skeleton} width={55} height="10%" />
                        </div>
                    </div>
                    <Skeleton className={cx(classes.skeleton, classes.horizontalBar)} width="100%" height="3px" />
                </div>
            </div>
        );
    }

    if (type === GraphTypeT.BAR_LINE) {
        return (
            <div className={classes.loadingContainerHorizontal}>
                <Skeleton className={classes.skeleton} width="3px" height="100%" />
                <div className={classes.loadingContainerVertical}>
                    <Grid display="flex" alignItems="center" paddingBottom={'100px'} width="100%" padding="100px">
                        <Skeleton variant="circular" width="15px" height="15px" />
                        <Skeleton variant="rectangular" width="33%" height="3px" />
                        <Skeleton variant="circular" width="15px" height="15px" />
                        <Skeleton variant="rectangular" width="33%" height="3px" />
                        <Skeleton variant="circular" width="15px" height="15px" />
                        <Skeleton variant="rectangular" width="33%" height="3px" />
                        <Skeleton variant="circular" width="15px" height="15px" />
                    </Grid>
                    <Skeleton className={cx(classes.skeleton, classes.horizontalBar)} width="100%" height="3px" />
                </div>
            </div>
        );
    }

    if (type === GraphTypeT.MULTI_BAR) {
        return (
            <div className={classes.loadingContainerHorizontal}>
                <Skeleton className={classes.skeleton} width="3px" height="100%" />
                <div className={classes.loadingContainerVertical}>
                    <div className={classes.loadingBars}>
                        <div className={classes.multiBar}>
                            <Skeleton className={classes.skeleton} width={10} height="80%" />
                            <Skeleton className={classes.skeleton} width={10} height="50%" />
                            <Skeleton className={classes.skeleton} width={10} height="15%" />
                            <Skeleton className={classes.skeleton} width={10} height="90%" />
                            <Skeleton className={classes.skeleton} width={10} height="60%" />
                            <Skeleton className={classes.skeleton} width={10} height="90%" />
                            <Skeleton className={classes.skeleton} width={10} height="50%" />
                        </div>
                        <div className={classes.multiBar}>
                            <Skeleton className={classes.skeleton} width={10} height="50%" />
                            <Skeleton className={classes.skeleton} width={10} height="80%" />
                            <Skeleton className={classes.skeleton} width={10} height="70%" />
                            <Skeleton className={classes.skeleton} width={10} height="25%" />
                            <Skeleton className={classes.skeleton} width={10} height="55%" />
                            <Skeleton className={classes.skeleton} width={10} height="90%" />
                            <Skeleton className={classes.skeleton} width={10} height="50%" />
                        </div>
                        <div className={classes.multiBar}>
                            <Skeleton className={classes.skeleton} width={10} height="75%" />
                            <Skeleton className={classes.skeleton} width={10} height="30%" />
                            <Skeleton className={classes.skeleton} width={10} height="5%" />
                            <Skeleton className={classes.skeleton} width={10} height="90%" />
                            <Skeleton className={classes.skeleton} width={10} height="50%" />
                            <Skeleton className={classes.skeleton} width={10} height="75%" />
                            <Skeleton className={classes.skeleton} width={10} height="85%" />
                        </div>
                        <div className={classes.multiBar}>
                            <Skeleton className={classes.skeleton} width={10} height="40%" />
                            <Skeleton className={classes.skeleton} width={10} height="60%" />
                            <Skeleton className={classes.skeleton} width={10} height="90%" />
                            <Skeleton className={classes.skeleton} width={10} height="50%" />
                            <Skeleton className={classes.skeleton} width={10} height="75%" />
                            <Skeleton className={classes.skeleton} width={10} height="85%" />
                            <Skeleton className={classes.skeleton} width={10} height="15%" />
                        </div>
                        <div className={classes.multiBar}>
                            <Skeleton className={classes.skeleton} width={10} height="60%" />
                            <Skeleton className={classes.skeleton} width={10} height="75%" />
                            <Skeleton className={classes.skeleton} width={10} height="90%" />
                            <Skeleton className={classes.skeleton} width={10} height="50%" />
                            <Skeleton className={classes.skeleton} width={10} height="15%" />
                            <Skeleton className={classes.skeleton} width={10} height="25%" />
                            <Skeleton className={classes.skeleton} width={10} height="35%" />
                        </div>
                        <div className={classes.multiBar}>
                            <Skeleton className={classes.skeleton} width={10} height="40%" />
                            <Skeleton className={classes.skeleton} width={10} height="90%" />
                            <Skeleton className={classes.skeleton} width={10} height="10%" />
                            <Skeleton className={classes.skeleton} width={10} height="85%" />
                            <Skeleton className={classes.skeleton} width={10} height="5%" />
                        </div>
                    </div>
                    <Skeleton className={cx(classes.skeleton, classes.horizontalBar)} width="100%" height="3px" />
                </div>
            </div>
        );
    }
};
