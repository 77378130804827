import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
import { useGetTillagePracticeQuery } from '@modules/results/api/summary.api';
import { tillagePracticeGraphTheme } from '@modules/results/utils/theme/tillagePracticeGraphTheme';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { TillagePracticeResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';

export const useTillagePracticeGraphLogic = (showHistoryYears: boolean) => {
    const tillagePracticeGraphRef = useRef(null);
    const { currentSeasonId } = useCurrentSeasonId();
    const { t } = useTranslation();
    const {
        data: tillagePracticeData,
        isLoading: isLoadingTillagePractice,
        isFetching: isFetchingTillagePractice,
        isError,
    } = useGetTillagePracticeQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: TillagePracticeResponseT | undefined } = useToggleHistoryYears({
        data: tillagePracticeData,
        showHistoryYears,
    });

    useEffect(() => {
        if (data && tillagePracticeGraphRef.current) {
            const tillagePracticeGraph = echarts.init(tillagePracticeGraphRef.current, tillagePracticeGraphTheme, {
                renderer: 'svg',
            });

            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            Object.keys(data).forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                const tillagePractice = data[harvestYear];
                formattedData[translatedHarvestYear] = {};
                tillagePractice.forEach((organicFert) => {
                    formattedData[translatedHarvestYear][t(organicFert.slug)] = { value: organicFert.total_area };
                });
            });

            const option = newGraphBuilder({
                type: GraphTypeT.STACKED_BAR,
                data: formattedData,
                yLabel: t('results.summary.tillage-practice-graph.ylabel'),
                tooltipTitle: t('results.summary.tillage-practice-graph.tooltip.title'),
                tooltipUnit: t('constants.hectare-unit'),
            });

            tillagePracticeGraph.setOption(option);
            return () => {
                tillagePracticeGraph.dispose();
            };
        }
    }, [data, t]);

    return {
        isError,
        tillagePracticeGraphRef,
        isLoading: isLoadingTillagePractice || isFetchingTillagePractice,
    };
};
