export const cropRotationGraphTheme = {
    color: [
        '#744B1B',
        '#C5DDD0',
        '#FFF1CE',
        '#FFB34E',
        '#2D7059',
        '#FDD980',
        '#84C19F',
        '#E6C49E',
        '#D89C58',
        '#CBD6FF',
        '#FFC3B5',
        '#85A0FF',
        '#ED8770',
        '#413EDD',
        '#C14022',
    ],
};
