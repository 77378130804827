import authSlice from '@modules/auth/store/authSlice';
import saveStatusSlice from '@modules/encoding/modules/layout/store/saveStatus.slice';
import { filterSlice } from '@modules/encoding/modules/rotation/store/filter.slice';
import { fieldDefinitionSlice } from '@modules/encoding/shared/store/fieldDefinitionSlice';
import {
    fieldSelectionAssignationSlice,
    fieldSelectionDuplicationSlice,
} from '@modules/encoding/shared/store/fieldSelectionSlice';
import { resultsEarningSeasonSelectionSlice } from '@modules/results/store/resultsEarningSeasonSelectionSlice';
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import api from '@shared/api';
import partnerPortalApi from '@shared/api/partnerPortalApi';
const listenerMiddleware = createListenerMiddleware();

export default configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        [authSlice.name]: authSlice.reducer,
        [saveStatusSlice.name]: saveStatusSlice.reducer,
        [filterSlice.name]: filterSlice.reducer,
        [fieldSelectionAssignationSlice.name]: fieldSelectionAssignationSlice.reducer,
        [fieldSelectionDuplicationSlice.name]: fieldSelectionDuplicationSlice.reducer,
        [fieldDefinitionSlice.name]: fieldDefinitionSlice.reducer,
        [partnerPortalApi.reducerPath]: partnerPortalApi.reducer,
        [resultsEarningSeasonSelectionSlice.name]: resultsEarningSeasonSelectionSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            api.middleware,
            listenerMiddleware.middleware,
            partnerPortalApi.middleware,
        ),
});
