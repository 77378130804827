import { useGetResidueManagementQuery } from '@modules/results/api/summary.api';
import { residueManagementGraphTheme } from '@modules/results/utils/theme/residueManagementGraphTheme';
import * as echarts from 'echarts';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { ResidueManagementResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';

export const useResidueManagementGraphLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const {
        data: residueManagementGraphData,
        isLoading: isLoadingResidueManagement,
        isFetching: isFetchingResidueManagement,
        isError,
    } = useGetResidueManagementQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: ResidueManagementResponseT | undefined } = useToggleHistoryYears({
        data: residueManagementGraphData,
        showHistoryYears,
    });

    const residueManagementGraphRef = useRef(null);
    const hasNoData = !isLoadingResidueManagement && (isError || !data || Object.keys(data).length === 0);

    useEffect(() => {
        if (data && residueManagementGraphRef.current) {
            const residueManagementGraph = echarts.init(
                residueManagementGraphRef.current,
                residueManagementGraphTheme,
                {
                    renderer: 'svg',
                },
            );

            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            Object.keys(data).forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                const residueData = data[harvestYear];
                formattedData[translatedHarvestYear] = residueData.reduce((acc, item) => {
                    const translatedSlug = t(`results.summary.residue-management-graph.legend.${item.slug}`);

                    acc[translatedSlug] = { value: item.value };

                    return acc;
                }, {} as Record<string, GraphDataItemT>);
            });

            const options = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.STACKED_BAR,
                yLabel: t('results.summary.residue-management-graph.ylabel'),
                tooltipTitle: t('results.summary.residue-management-graph.tooltip.title'),
                tooltipUnit: t('constants.hectare-unit'),
            });

            residueManagementGraph.setOption(options);

            return () => {
                residueManagementGraph.dispose();
            };
        }
    }, [data, t]);

    return {
        hasNoData,
        residueManagementGraphRef,
        isLoading: isLoadingResidueManagement || isFetchingResidueManagement,
        t,
    };
};
