export const adjustChartLegendHeight = (myChart: echarts.ECharts, chartId: string) => {
    setTimeout(() => {
        const legends = myChart.getOption()?.legend as echarts.LegendComponentOption[] | undefined;
        if (!legends || !legends.length) return;
        const chartContainer = document.getElementById(chartId);
        if (!chartContainer) return;

        const legend = legends[0];
        const itemCount = legend.data?.length ?? 0;
        const legendWords = legend.data as string[];
        const legendCharacters = legendWords.reduce((acc, label) => acc + label.length, 0);

        // If there are less than 5 items, no need to adjust the chart size
        if (itemCount < 5) return;

        const itemHeight = legend.itemHeight || 10;
        const itemGap = legend.itemGap || 5;

        const charDensity = chartContainer.clientWidth / 8;
        const overflowFactor = Math.max(0, legendCharacters - charDensity);
        const labelRowOverflow = Math.ceil(overflowFactor / charDensity);

        const estimatedLegendHeight =
            itemCount > 10
                ? Math.max(8, itemCount / 2) * (itemHeight + itemGap) - 100 + labelRowOverflow * (itemHeight + itemGap)
                : (itemCount * (itemHeight + itemGap)) / 2 + labelRowOverflow * (itemHeight + itemGap);

        const baseChartHeight = 280;
        const newHeight = baseChartHeight + estimatedLegendHeight;

        chartContainer.style.height = `${newHeight}px`;

        const grids = myChart.getOption()?.grid as echarts.GridComponentOption[] | undefined;
        if (!grids || !grids.length) return;
        const grid = grids[0];

        grid.top = 20;
        grid.bottom = estimatedLegendHeight;
        myChart.setOption({ grid }, false, true);

        myChart.resize();
    }, 200);
};
