import { useGetIrrigationDataQuery } from '@modules/results/api/summary.api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';
import { IrrigationResponseT } from '@modules/results/api/summary.types';

enum displayUnits {
    MM_PER_HA = 'mm_per_ha',
    PERCENTAGE_OF_CROP_SURFACE = 'percentage_of_crop_surface',
    HECTARS = 'hectars',
}

export const useIrrigationGraphLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const irrigationGraphRef = useRef(null);
    const [displayUnit, setDisplayUnit] = useState<{ value: displayUnits; label: string }>({
        value: displayUnits.MM_PER_HA,
        label: t(`results.summary.irrigation.ylabel.${displayUnits.MM_PER_HA}`),
    });

    const displayUnitOptions = useMemo(
        () => [
            {
                value: displayUnits.MM_PER_HA,
                label: t(`results.summary.irrigation.ylabel.${displayUnits.MM_PER_HA}`),
            },
            {
                value: displayUnits.PERCENTAGE_OF_CROP_SURFACE,
                label: t(`results.summary.irrigation.ylabel.${displayUnits.PERCENTAGE_OF_CROP_SURFACE}`),
            },
            {
                value: displayUnits.HECTARS,
                label: t(`results.summary.irrigation.ylabel.${displayUnits.HECTARS}`),
            },
        ],
        [t],
    );

    const handleSwitchDisplayUnit = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const unit = event.target.value as displayUnits;
            setDisplayUnit({
                value: unit,
                label: t(`results.summary.irrigation.ylabel.${unit}`),
            });
        },
        [t],
    );

    const {
        data: irrigationData,
        isLoading: isLoadingIrrigationData,
        isFetching: isFetchingIrrigationData,
        isError,
    } = useGetIrrigationDataQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: IrrigationResponseT | undefined } = useToggleHistoryYears({
        data: irrigationData,
        showHistoryYears,
    });

    useEffect(() => {
        if (data && irrigationGraphRef.current) {
            const irrigationGraph = echarts.init(irrigationGraphRef.current, { color: '#413EDD' }, { renderer: 'svg' });

            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            const yLabel = t(`results.summary.irrigation.ylabel.${displayUnit.value}`);
            const tooltipUnit = t(`results.summary.irrigation.tooltip.unit.${displayUnit.value}`);

            switch (displayUnit.value) {
                case displayUnits.MM_PER_HA:
                    Object.keys(data).forEach((harvestYear) => {
                        const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                        formattedData[translatedHarvestYear] = {};
                        const sumMmPerHa = data[harvestYear].reduce((acc, curr) => acc + curr.mm_per_ha, 0);
                        formattedData[translatedHarvestYear][t('results.summary.irrigation.legend.label')] = {
                            value: sumMmPerHa,
                        };
                    });
                    break;
                case displayUnits.PERCENTAGE_OF_CROP_SURFACE:
                    Object.keys(data).forEach((harvestYear) => {
                        const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                        formattedData[translatedHarvestYear] = {};
                        const totalIrrigatedArea = data[harvestYear].reduce(
                            (acc, curr) => acc + curr.irrigated_area,
                            0,
                        );
                        if (data[harvestYear][0] && data[harvestYear][0].total_farm_area) {
                            formattedData[translatedHarvestYear][t('results.summary.irrigation.legend.label')] = {
                                value: totalIrrigatedArea / data[harvestYear][0].total_farm_area,
                            };
                        } else {
                            formattedData[translatedHarvestYear][t('results.summary.irrigation.legend.label')] = {
                                value: 0,
                            };
                        }
                    });
                    break;
                case displayUnits.HECTARS:
                    Object.keys(data).forEach((harvestYear) => {
                        const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                        formattedData[translatedHarvestYear] = {};
                        const totalIrrigatedArea = data[harvestYear].reduce(
                            (acc, curr) => acc + curr.irrigated_area,
                            0,
                        );
                        formattedData[translatedHarvestYear][t('results.summary.irrigation.legend.label')] = {
                            value: totalIrrigatedArea,
                        };
                    });
                    break;
                default:
                    break;
            }

            const options = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.BAR_LINE,
                yLabel,
                tooltipTitle: t('results.summary.irrigation.tooltip.title'),
                tooltipUnit,
                showTotal: false,
                showTooltipPercentage: false,
            });

            irrigationGraph.setOption(options);

            return () => {
                irrigationGraph.dispose();
            };
        }
    }, [displayUnit, data, t]);

    return {
        t,
        irrigationGraphRef,
        displayUnit,
        displayUnitOptions,
        handleSwitchDisplayUnit,
        isLoading: isLoadingIrrigationData || isFetchingIrrigationData,
        isError,
    };
};
