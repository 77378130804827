import { TableSkeleton } from '../../newShared/TableSkeleton/TableSkeleton';
import { useIrrigationTableLogic } from './IrrigationTable.logic';
import { useIrrigationTableStyles } from './IrrigationTable.style';
import { MenuItem, Select, Table } from '@soil-capital/ui-kit/components';

export const IrrigationTable = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { selectedHarvestYear, harvestOptions, handleHarvestChange, formattedIrrigationPerCrop, isLoading, t } =
        useIrrigationTableLogic(showHistoryYears);
    const { classes } = useIrrigationTableStyles();

    if (formattedIrrigationPerCrop.headers.length === 0 && !isLoading) {
        return null;
    }

    return (
        <div className={classes.tableContainer}>
            {isLoading ? (
                <TableSkeleton />
            ) : (
                <>
                    <Select
                        value={selectedHarvestYear}
                        onChange={handleHarvestChange}
                        sx={{ width: '240px', maxHeight: '46px' }}
                    >
                        {harvestOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {t(option)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Table
                        headers={formattedIrrigationPerCrop.headers}
                        data={formattedIrrigationPerCrop.data}
                        seeLessLabel={t('constants.see-less')}
                        seeMoreLabel={t('constants.see-more')}
                        seeNextLabel={t('constants.see-next')}
                        stickyFirstColumn
                    />
                </>
            )}
        </div>
    );
};
