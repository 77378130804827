import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetChalkQuery } from '@modules/results/api/summary.api';
import { chalkResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import * as echarts from 'echarts';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const useChalkGraphLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const {
        data: chalkData,
        isLoading: isLoadingChalk,
        isFetching: isFetchingChalk,
        isError,
    } = useGetChalkQuery({
        farmSeasonId: currentSeasonId,
    });
    const { data }: { data: chalkResponseT | undefined } = useToggleHistoryYears({
        data: chalkData,
        showHistoryYears,
    });
    const chalkGraphRef = useRef(null);

    useEffect(() => {
        if (data && chalkGraphRef.current) {
            const chalkGraph = echarts.init(chalkGraphRef.current, { color: '#C14022' }, { renderer: 'svg' });

            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            Object.keys(data).forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                const chalkPerYear = data[harvestYear] as unknown as Record<string, number>;
                formattedData[translatedHarvestYear] = {};

                Object.keys(chalkPerYear).forEach((total_quantity) => {
                    formattedData[translatedHarvestYear][t('results.summary.chalk-graph.legend.label')] = {
                        value: chalkPerYear[total_quantity],
                    };
                });
            });

            const options = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.BAR_LINE,
                yLabel: t('results.summary.chalk-graph.ylabel'),
                tooltipTitle: t('results.summary.chalk-graph.tooltip.title'),
                tooltipUnit: t('results.summary.chalk-graph.tooltip.unit'),
                showTooltipPercentage: false,
                showTotal: false,
            });

            chalkGraph.setOption(options);

            return () => {
                chalkGraph.dispose();
            };
        }
    }, [data, t]);

    return {
        t,
        chalkGraphRef,
        isLoading: isLoadingChalk || isFetchingChalk,
        isError,
    };
};
