import {
    FarmSeasonNitrogenPerCropResponseT,
    FarmSeasonMineralFertilisersResponseT,
    CropRotationResponseT,
    FarmSeasonOrganicFertiliserResponseT,
    CropFertiliserByHarvestYearT,
    IrrigationResponseT,
    chalkResponseT,
    CoverCropsResponseT,
    LegumesResponseT,
    livestockResponseT,
    MineralFertilisationNutrientsResponseT,
    FertilisersByHarvestYearT,
    FarmSeasonOrganicFertiliserPerCropResponseT,
    TillagePracticeResponseT,
    ResidueManagementResponseT,
    FuelConsumptionResponseT,
} from '../api/summary.types';

type ToggleHistoryYearsPropsT = {
    data:
        | livestockResponseT
        | ResidueManagementResponseT
        | TillagePracticeResponseT
        | CropFertiliserByHarvestYearT
        | FertilisersByHarvestYearT
        | FarmSeasonOrganicFertiliserPerCropResponseT
        | undefined
        | MineralFertilisationNutrientsResponseT
        | LegumesResponseT
        | FuelConsumptionResponseT
        | chalkResponseT
        | CoverCropsResponseT
        | IrrigationResponseT
        | FarmSeasonNitrogenPerCropResponseT
        | FarmSeasonMineralFertilisersResponseT
        | CropRotationResponseT
        | FarmSeasonOrganicFertiliserResponseT
        | CropFertiliserByHarvestYearT;
    showHistoryYears: boolean;
};

export const useToggleHistoryYears = ({ data, showHistoryYears }: ToggleHistoryYearsPropsT) => {
    return {
        data: showHistoryYears || !data ? data : Object.fromEntries(Object.entries(data).slice(2)),
    };
};
