import { GraphBuilderPropsT, GraphTypeT } from './graphBuilder.types';

/**
 * Builds a configuration object for various chart types using ECharts.
 * Supports stacked bar charts, multi bar charts, and bar-line combination charts.
 *
 * @param {object} params - The parameters for configuring the graph.
 * @param {GraphData} params.data - The dataset for the graph.
 *   - Keys of the outer object represent categories on the x-axis.
 *   - Each inner object contains key-value pairs where keys are series names and values are the data points.
 * @param {GraphType} params.type - The type of graph to create (STACKED_BAR, MULTI_BAR, or BAR_LINE).
 * @param {string} params.yLabel - Label for the y-axis.
 * @param {string} [params.tooltipTitle] - (Optional) Title for the tooltip.
 * @param {string} [params.tooltipUnit=''] - (Optional) Unit displayed in tooltips.
 * @param {boolean} [params.showTotal] - (Optional) Whether or not to display a total value in the tooltip.
 * @param {boolean} [params.showTooltipPercentage] - (Optional) Whether or not to display a percentage in the tooltip.
 * @param {number} [params.tooltipTotal] - (Optional) A fixed total value to display in the tooltip.
 * @param {string} [params.tooltipLabel] - (Optional) Label for the tooltip, default is serieName.
 * @param {string} [params.selectedXAxis] - (Optional) Highlight this x-axis label with special styling.
 * @param {string} [params.referenceXAxis] - (Optional) Highlight this x-axis label as a reference point.
 * @returns {object} An ECharts option object for rendering the specified graph type.
 *
 * @example
 * // Example data input:
 * const data = {
 *   '2020': { 'Series1': {value: 10, is_organic: false}, 'Series2': {value: 20, is_organic: false} },
 *   '2021': { 'Series1': {value: 15, is_organic: false}, 'Series2': {value: 25, is_organic: false} },
 *   '2022': { 'Series1': {value: 20, is_organic: false}, 'Series2': {value: 30, is_organic: false} }
 * };
 *
 * // Stacked bar chart example:
 * const stackedOptions = newGraphBuilder({
 *   data,
 *   type: GraphType.STACKED_BAR,
 *   yLabel: 'Values',
 *   tooltipTitle: 'Annual Data',
 *   tooltipUnit: '%',
 *   selectedXAxis: '2021'
 * });
 *
 * // Multi bar chart example:
 * const multiBarOptions = newGraphBuilder({
 *   data,
 *   type: GraphType.MULTI_BAR,
 *   yLabel: 'Values'
 * });
 *
 * // Line chart example:
 * const lineChartOptions = newGraphBuilder({
 *   data,
 *   type: GraphType.BAR_LINE,
 *   yLabel: 'Values'
 * });
 *
 * // Using ECharts to render the chart:
 * const chart = echarts.init(document.getElementById('chart-container'));
 * chart.setOption(options);
 */
export const newGraphBuilder = ({
    data,
    type,
    yLabel = '',
    tooltipTitle,
    tooltipUnit = '',
    tooltipLabel,
    tooltipTotal,
    showTooltipPercentage = true,
    showSelectedXAxis = true,
    showReferenceXAxis = true,
    showTotal = true,
}: GraphBuilderPropsT) => {
    const categories = Object.keys(data);
    const selectedXAxis = showSelectedXAxis ? categories[categories.length - 1] : null;
    const referenceXAxis = showReferenceXAxis ? categories[2] : null;
    const allItems = new Set<string>();
    Object.values(data).forEach((categoryItems) => {
        Object.keys(categoryItems).forEach((item) => allItems.add(item));
    });
    const items = Array.from(allItems);

    const valueMatrix: number[][] = items.map((item) => categories.map((category) => data[category][item]?.value || 0));

    const topBarIndexByCategory = categories.map((_, categoryIndex) => {
        for (let s = items.length - 1; s >= 0; s--) {
            if (valueMatrix[s][categoryIndex] > 0) return s;
        }
        return -1;
    });

    const series = items.map((item, seriesIndex) => {
        const seriesData = categories.map((category, categoryIndex) => {
            const value = data[category][item]?.value || 0;
            const isTopBar = topBarIndexByCategory[categoryIndex] === seriesIndex;
            return {
                value: value,
                is_organic: data[category][item]?.is_organic,
                itemStyle:
                    type === GraphTypeT.STACKED_BAR && isTopBar
                        ? { borderRadius: [4, 4, 0, 0] }
                        : type === GraphTypeT.MULTI_BAR
                        ? { borderRadius: [10, 10, 0, 0] }
                        : { borderRadius: [0, 0, 0, 0] },
            };
        });

        const seriesConfig = {
            name: item,
            data: seriesData,
            type: type === GraphTypeT.BAR_LINE ? 'line' : 'bar',
            stack: type === GraphTypeT.STACKED_BAR ? 'stack' : undefined,
            barWidth: type === GraphTypeT.STACKED_BAR ? '30px' : '8px',
            z: 20,
            symbol: type === GraphTypeT.BAR_LINE ? 'circle' : (undefined as string | undefined),
            symbolSize: type === GraphTypeT.BAR_LINE ? 8 : (undefined as number | undefined),
            lineStyle: type === GraphTypeT.BAR_LINE ? { width: 2 } : (undefined as { width: number } | undefined),
            itemStyle: undefined as { borderRadius?: number[] } | undefined,
            barGap: type === GraphTypeT.MULTI_BAR ? '0.5px' : (undefined as string | undefined),
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(45, 112, 89, 0.4)',
                },
            },
        };

        return seriesConfig;
    });

    let yAxisMax = 0;
    if (type === GraphTypeT.STACKED_BAR) {
        const sumStackValues = categories.map((category) => {
            const values = Object.values(data[category]).filter(
                (val): val is { value: number } => typeof val.value === 'number',
            );
            return values.reduce((acc, val) => acc + val.value, 0);
        });
        yAxisMax = Math.max(...sumStackValues);
    } else {
        yAxisMax = Math.max(
            ...items.map((item) => Math.max(...categories.map((category) => data[category][item]?.value || 0))),
        );
    }
    const yAxisMin = 0;

    const graphOptions = {
        grid: {
            left: '5%',
            right: '5%',
            bottom: '25%',
            top: '10%',
        },
        tooltip: {
            show: true,
            trigger: 'axis',
            backgroundColor: '#323F45',
            textStyle: {
                color: '#fff',
            },
            borderWidth: 0,
            appendToBody: true,
            axisPointer: {
                type: 'none',
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formatter: function (params: any) {
                const totalPercent = 100;
                const totalStackValue = params
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .reduce((sum: any, item: any) => sum + item.value, 0)
                    .toFixed(2)
                    .replace(/\.?0+$/, '');

                let html = `
                    <div style="padding: 4px 8px; font-size: 12px; font-family: BrownStd-Bold, BrownStd-Regular, Roboto, Helvetica, Arial, sans-serif; color: #FFFFFF;">
                        <div style="text-align: left; font-size: 14px; text-transform: uppercase; color: #BFCBD1; font-weight: 700; margin-bottom: 12px;">
                            ${tooltipTitle || ''}
                        </div>
                        <div style="
                        display: grid;
                        grid-template-columns: auto auto auto;
                        gap: 8px;
                    ">
                `;

                const paramsToDisplay = [...params];
                if (type === GraphTypeT.STACKED_BAR) {
                    paramsToDisplay.reverse();
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                paramsToDisplay.forEach((item: any) => {
                    if ((!item.value || item.value === 0) && !(item.value === 0 && paramsToDisplay.length === 1)) {
                        return;
                    }
                    const itemValue = Number(item.value)
                        .toFixed(2)
                        .replace(/\.?0+$/, '');

                    const percent =
                        totalStackValue > 0
                            ? ((item.value / totalStackValue) * 100).toFixed(1).replace(/\.?0+$/, '')
                            : 0;
                    html += `
                        <div style="text-align: left; display: flex; align-items: center; ">
                            <span style="display: inline-block; width: 12px; height: 12px; background-color: ${
                                item.color
                            }; border-radius: 50%; margin-right: 10px;"></span>
                            ${tooltipLabel ?? item.seriesName}
                        </div>
                    `;
                    if (showTooltipPercentage) {
                        html += `
                            <div style="text-align: center;">${itemValue} ${tooltipUnit || ''}</div>
                            <div style="text-align: right; color: #BFCBD1;">${percent}%</div>`;
                    } else {
                        html += `
                            <div></div>
                            <div style="text-align: right; color: #BFCBD1;">${itemValue} ${tooltipUnit || ''}</div>`;
                    }
                });

                if (showTotal) {
                    html += `
                    <div style="padding-left:22px; padding-top: 2px; text-align: left; font-size: 14px;">Total</div>
                    <div style="padding-top: 2px; text-align: center;">${tooltipTotal || totalStackValue} ${
                        tooltipUnit || ''
                    }</div>
                    <div style="padding-top: 2px; text-align: right; color: #BFCBD1;">${totalPercent}%</div>
                </div>`;
                }

                return html;
            },
        },
        legend: {
            show: true,
            data: items,
            bottom: '5%',
            orient: 'horizontal',
            left: 'center',
            icon: type !== GraphTypeT.BAR_LINE ? 'circle' : undefined,
            itemWidth: 20,
            itemHeight: 10,
            itemGap: 20,
            textStyle: {
                fontSize: 14,
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
                rich: {
                    organic: {
                        backgroundColor: {
                            image: '/assets/images/organic-logo.png',
                        },
                        height: 20,
                        width: 30,
                        align: 'right',
                    },
                    value: {
                        width: '100%',
                        align: 'left',
                        fontSize: 14,
                        fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                        color: '#323F45',
                    },
                },
            },
            formatter: function (name: string) {
                for (const year in data) {
                    if (data[year][name]?.is_organic) {
                        return `{value|${name}} {organic|}`;
                    }
                }
                return name;
            },
        },
        xAxis: {
            nameLocation: 'center',
            type: 'category',
            data: categories,
            axisLine: {
                show: true,
                lineStyle: {
                    width: 2,
                    color: '#333',
                },
            },
            axisTick: { show: false },
            splitLine: {
                show: true,
                alignWithLabel: true,
                lineStyle: {
                    color: '#ccc',
                    type: 'solid',
                },
            },
            axisLabel: {
                interval: 0,
                show: true,
                fontSize: 14,
                fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                color: '#323F45',
                formatter: (value: string) => {
                    if (selectedXAxis && value === selectedXAxis) {
                        return `{selected|${value}}`;
                    }
                    if (referenceXAxis && value === referenceXAxis) {
                        return `{reference|${value}}`;
                    }
                    return value;
                },
                rich: {
                    selected: {
                        fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                        color: '#BD7A2D',
                        fontSize: 14,
                    },
                    reference: {
                        fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                        color: '#679188',
                        fontSize: 14,
                    },
                },
            },
            boundaryGap: true,
            z: 10,
        },
        yAxis: [
            // Main axis: standard labels and outside ticks
            {
                position: 'left',
                nameLocation: 'center',
                nameGap: 55,
                name: yLabel,
                nameRotate: 90,
                nameTextStyle: {
                    fontSize: 14,
                    fontWeight: 'bold',
                    fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                    color: '#333',
                },
                type: 'value',
                offset: -45,
                min: yAxisMin + 0.1 * yAxisMin - 0.1 * yAxisMax,
                max: yAxisMax + 0.1 * yAxisMax - 0.1 * yAxisMin,
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisTick: {
                    show: true,
                    length: 7,
                    inside: false,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: true,
                    fontWeight: 'bold',
                    showMaxLabel: false,
                    showMinLabel: false,
                    margin: 12,
                    fontSize: 14,
                    fontFamily: ['BrownStd-Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
                    color: '#323F45',
                    formatter: (value: number) => (value === 0 ? '' : value), // Hide label for 0
                },
                splitLine: {
                    show: false,
                },
                z: 10,
            },
            // Secondary axis: only inside ticks, no labels
            {
                position: 'left',
                type: 'value',
                offset: -45,
                min: yAxisMin + 0.1 * yAxisMin - 0.1 * yAxisMax,
                max: yAxisMax + 0.1 * yAxisMax - 0.1 * yAxisMin,
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisTick: {
                    show: true,
                    length: 7,
                    inside: true,
                    lineStyle: {
                        width: 2,
                        color: '#333',
                    },
                },
                axisLabel: {
                    show: false,
                    showMaxLabel: false,
                    showMinLabel: false,
                },
                splitLine: {
                    show: false,
                },
                z: 10,
            },
        ],
        series: series,
    };

    return graphOptions;
};
