import { useTranslation } from 'react-i18next';
import { useLivestockGraphLogic } from './LivestockGraph.logic';
import { useLivestockGraphStyles } from './LivestockGraph.style';
import { IconCow } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';

export const LivestockGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { t } = useTranslation();
    const { livestockGraphRef, isLoading, isError } = useLivestockGraphLogic(showHistoryYears);
    const { classes } = useLivestockGraphStyles();

    return (
        <GraphContainer
            hasBorders={false}
            isLoading={isLoading}
            type={GraphTypeT.BAR_LINE}
            title={t('results.summary.livestock-graph.title')}
            titleIcon={<IconCow className={classes.icon} fontSize="large" />}
            hasNoData={isError}
        >
            <div ref={livestockGraphRef} className={classes.container}></div>
        </GraphContainer>
    );
};
