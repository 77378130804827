import { makeStyles } from '@soil-capital/ui-kit/style';

export const useGraphSkeletonStyles = makeStyles()((theme) => ({
    loadingContainerHorizontal: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        display: 'flex',
        justifyContent: 'center',
        transform: 'translate(-50%, -45%)',
        alignItems: 'center',
        width: '90%',
        height: '70%',
        gap: theme.spacing(1),
    },
    loadingContainerVertical: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        gap: theme.spacing(1),
    },
    loadingBars: {
        width: '100%',
        height: '100%',
        display: 'flex',
        paddingLeft: '5%',
        paddingRight: '5%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    skeleton: {
        transform: 'none',
        borderRadius: theme.shape.borderRadiusS,
    },
    horizontalBar: {
        transform: `translate(-${theme.spacing(0.9)}, 0%)`,
    },
    stackedBar: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: theme.spacing(0.5),
        justifyContent: 'flex-end',
    },
    multiBar: {
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        gap: theme.spacing(0.5),
    },
}));
