import { IconSeed } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { useResidueManagementStyles } from './ResidueManagement.style';
import { useResidueManagementGraphLogic } from './ResidueManagementGraph.logic';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';

export const ResidueManagementGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { residueManagementGraphRef, isLoading, t, hasNoData } = useResidueManagementGraphLogic(showHistoryYears);
    const { classes } = useResidueManagementStyles();
    return (
        <GraphContainer
            title={t('results.summary.residue-management-graph.title')}
            isLoading={isLoading}
            titleIcon={<IconSeed className={classes.icon} fontSize="large" />}
            type={GraphTypeT.STACKED_BAR}
            hasNoData={hasNoData}
        >
            <div ref={residueManagementGraphRef} className={classes.graphContainer}></div>
        </GraphContainer>
    );
};
