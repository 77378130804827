import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResultsSummaryStyle = makeStyles()((theme) => ({
    content: {
        padding: 0,
        paddingTop: theme.spacing(3),
        gap: 0,
    },
    title: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: theme.spacing(1),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(1),
    },
    flexBox: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: theme.spacing(3),
        padding: theme.spacing(5),
    },
    image: {
        filter: 'grayscale(100%)',
        width: '120px',
        height: '120px',
    },
    text: { paddingTop: theme.spacing(1) },
    sequestration: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        backgroundColor: theme.palette.success.light,
        padding: theme.spacing(3),
    },
    emission: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        backgroundColor: theme.palette.error.light,
        padding: theme.spacing(3),
    },
    horizontalBarSequestration: {
        width: '100%',
        borderTop: '1px solid',
        borderColor: theme.palette.success.main,
        transform: 'translateY(2px)',
    },
    horizontalBarEmission: {
        width: '100%',
        borderTop: '1px solid',
        borderColor: theme.palette.error.main,
        transform: 'translateY(2px)',
    },
    grid: { display: 'grid', gridTemplateColumns: '50% 50%', gap: theme.spacing(3), marginRight: theme.spacing(3) },
    whiteBg: { backgroundColor: theme.palette.common.white },
    sectionTitle: { display: 'flex', alignItems: 'center', gap: theme.spacing(1) },
    cropRotationGraphContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    commentContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-center',
        gap: theme.spacing(1),
        marginTop: theme.spacing(1),
        color: theme.palette.darkScale[700],
    },
    icon: { color: theme.palette.darkScale[700] },
}));
