import { makeStyles } from 'tss-react/mui';

export const useNoDataFoundStyles = makeStyles()((theme) => ({
    image: {
        height: '72px',
        width: '72px',
        filter: 'grayscale(100%)',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(3),
        width: '100%',
        height: '100%',
    },
}));
