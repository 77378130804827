import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { useCallback } from 'react';

export const useSwitchView = ({
    handleSwitchView,
}: {
    view: GraphTypeT;
    handleSwitchView: (view: GraphTypeT) => void;
}) => {
    const graphViewOptions = [
        { value: GraphTypeT.STACKED_BAR, label: 'Stacked Bar' },
        { value: GraphTypeT.MULTI_BAR, label: 'Multi Bar' },
        { value: GraphTypeT.BAR_LINE, label: 'Line' },
    ];

    const toggleGraphView = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            const view = (event.target as HTMLSelectElement).value as GraphTypeT;
            handleSwitchView(view);
        },
        [handleSwitchView],
    );

    return {
        graphViewOptions,
        toggleGraphView,
    };
};
