import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetMineralFertilisersPerCropQuery } from '@modules/results/api/summary.api';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { CropFertiliserByHarvestYearT, FertilisersByHarvestYearT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';

export const useMineralFertilisationTableLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();

    const {
        data,
        isLoading: isLoadingFertilisersPerCrop,
        isFetching: isFetchingFertilisersPerCrop,
    } = useGetMineralFertilisersPerCropQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data: cropsData }: { data: CropFertiliserByHarvestYearT | undefined } = useToggleHistoryYears({
        data: data?.crops,
        showHistoryYears,
    });

    const { data: fertilisersData }: { data: FertilisersByHarvestYearT | undefined } = useToggleHistoryYears({
        data: data?.fertilisers,
        showHistoryYears,
    });

    const availableHarvestYears = useMemo(() => {
        return cropsData
            ? Object.keys(cropsData)
                  .filter((year) => {
                      const yearData = cropsData[year];
                      return Array.isArray(yearData) && yearData.length > 0;
                  })
                  .sort()
            : [];
    }, [cropsData]);

    const [selectedHarvestYear, setSelectedHarvestYear] = useState<string | null>(null);
    const handleHarvestChange = useCallback((event: SelectChangeEvent<unknown>) => {
        setSelectedHarvestYear((event.target as HTMLSelectElement).value);
    }, []);

    const formattedFertilisersPerCropData = useMemo(() => {
        if (!cropsData || !fertilisersData || !selectedHarvestYear) {
            return {
                headers: [],
                data: [[]],
                availableHarvestYears,
                selectedHarvestYear,
            };
        }

        const cropsForYear = cropsData[selectedHarvestYear] || [];
        const fertsForYear = fertilisersData[selectedHarvestYear] || [];

        const headers = [
            t('constants.crop'),
            t('results.summary.mineral-fertilisation-table.average'),
            ...fertsForYear.map((fert) => (fert.slug ? t(`${fert.slug}`) : fert.name)),
        ];

        const rows = cropsForYear.map((crop) => {
            const fertilisersForCrop = Array.isArray(crop.fertilisers) ? crop.fertilisers : [];

            const row = [t(`${crop.slug}`), `${crop.average_n_per_ha} U`];

            fertsForYear.forEach((fert) => {
                const totalQuantity = fertilisersForCrop
                    .filter((cf) => cf.id === fert.id)
                    .reduce((sum, cf) => sum + Number(cf.quantity), 0);
                const valuePerHa =
                    totalQuantity && crop.total_area
                        ? (totalQuantity / Number(crop.total_area)).toFixed(2).replace(/\.?0+$/, '')
                        : 0;
                row.push(`${valuePerHa} ${fert.unit?.toLowerCase()}/${t('constants.hectare-unit')}`);
            });

            return row;
        });

        return {
            headers,
            data: rows,
            availableHarvestYears,
            selectedHarvestYear,
        };
    }, [cropsData, fertilisersData, selectedHarvestYear, availableHarvestYears, t]);

    useEffect(() => {
        if (data && !selectedHarvestYear) {
            setSelectedHarvestYear(availableHarvestYears[availableHarvestYears.length - 1]);
        }
    }, [availableHarvestYears, data, selectedHarvestYear]);

    return {
        formattedFertilisersPerCropData,
        isLoading: isLoadingFertilisersPerCrop || isFetchingFertilisersPerCrop,
        selectedHarvestYear,
        handleHarvestChange,
        availableHarvestYears,
    };
};
