import { useGetCoverCropsQuery } from '@modules/results/api/summary.api';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';
import { coverCropsGraphTheme } from '@modules/results/utils/theme/coverCropsGraphTheme';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { CoverCropsResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';

export const useCoverCropsGraphLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const coverCropsGraphRef = useRef(null);

    const {
        data: coverCropsData,
        isLoading: isLoadingCoverCropsData,
        isFetching: isFetchingCoverCropsData,
        isError,
    } = useGetCoverCropsQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: CoverCropsResponseT | undefined } = useToggleHistoryYears({
        data: coverCropsData,
        showHistoryYears,
    });

    useEffect(() => {
        if (data && coverCropsGraphRef.current) {
            const coverCropsGraph = echarts.init(coverCropsGraphRef.current, coverCropsGraphTheme, { renderer: 'svg' });

            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            Object.keys(data).forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                const coverCropsPerYear = data[harvestYear] as unknown as Record<string, number>;

                formattedData[translatedHarvestYear] = {};

                Object.keys(coverCropsPerYear).forEach((slug) => {
                    const translatedSlug = t(slug);

                    formattedData[translatedHarvestYear][translatedSlug] = { value: coverCropsPerYear[slug] };
                });
            });
            const options = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.STACKED_BAR,
                yLabel: t('results.summary.cover-crops-graph.ylabel'),
                tooltipTitle: t('results.summary.cover-crops-graph.tooltip.title'),
                tooltipUnit: t('constants.hectare-unit'),
            });

            coverCropsGraph.setOption(options);

            return () => {
                coverCropsGraph.dispose();
            };
        }
    }, [data, t]);

    return {
        t,
        coverCropsGraphRef,
        isLoading: isLoadingCoverCropsData || isFetchingCoverCropsData,
        isError,
    };
};
