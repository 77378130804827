import { useGetLegumesQuery } from '@modules/results/api/summary.api';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { LegumesResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';

export const useLegumeGraphLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const legumesGraphRef = useRef(null);

    const {
        data: legumeData,
        isLoading: isLoadingLegumeData,
        isFetching: isFetchingLegumeData,
        isError,
    } = useGetLegumesQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: LegumesResponseT | undefined } = useToggleHistoryYears({
        data: legumeData,
        showHistoryYears,
    });

    useEffect(() => {
        if (data && legumesGraphRef.current) {
            const legumesGraph = echarts.init(legumesGraphRef.current, { color: '#D89C58' }, { renderer: 'svg' });

            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            Object.keys(data).forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                formattedData[translatedHarvestYear] = {
                    [t('results.summary.legumes-graph.legend.label')]: { value: data[harvestYear].total_legume_area },
                };
            });

            const options = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.BAR_LINE,
                yLabel: t('results.summary.legumes-graph.ylabel'),
                tooltipTitle: t('results.summary.legumes-graph.tooltip.title'),
                tooltipUnit: t('constants.hectare-unit'),
                showTooltipPercentage: false,
                showTotal: false,
            });

            legumesGraph.setOption(options);

            return () => {
                legumesGraph.dispose();
            };
        }
    }, [data, t]);

    return {
        t,
        legumesGraphRef,
        isLoading: isLoadingLegumeData || isFetchingLegumeData,
        isError,
    };
};
