import { useChalkGraphLogic } from './ChalkGraph.logic';
import { useChalkGraphStyles } from './ChalkGraph.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { IconMineralFertilisation } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';

export const ChalkGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { t, chalkGraphRef, isLoading, isError } = useChalkGraphLogic(showHistoryYears);
    const { classes } = useChalkGraphStyles();

    return (
        <GraphContainer
            title={t('results.summary.chalk-graph.title')}
            type={GraphTypeT.BAR_LINE}
            isLoading={isLoading}
            titleIcon={<IconMineralFertilisation className={classes.icon} fontSize="large" />}
            hasNoData={isError}
        >
            <div ref={chalkGraphRef} className={classes.container}></div>
            <Typography variant="body2">{t('results.summary.chalk-graph.helper')}</Typography>
        </GraphContainer>
    );
};
