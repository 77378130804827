import { useTranslation } from 'react-i18next';
import { useTillagePracticeGraphStyles } from './TillagePracticeGraph.style';
import { useTillagePracticeGraphLogic } from './TillagePracticeGraph.logic';
import { IconTractor } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';

export const TillagePracticeGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { t } = useTranslation();
    const { classes } = useTillagePracticeGraphStyles();
    const { tillagePracticeGraphRef, isLoading, isError } = useTillagePracticeGraphLogic(showHistoryYears);

    return (
        <GraphContainer
            title={t('results.summary.tillage-practice-graph.title')}
            isLoading={isLoading}
            type={GraphTypeT.STACKED_BAR}
            titleIcon={<IconTractor className={classes.icon} fontSize="large" />}
            hasNoData={isError}
        >
            <div ref={tillagePracticeGraphRef} className={classes.graphContainer} />
        </GraphContainer>
    );
};
