import { Typography } from '@soil-capital/ui-kit/material-core';
import { ReactNode } from 'react';
import { useSectionContainerStyles } from './SectionContainer.style';

export const SectionContainer = ({
    title,
    titleIcon,
    children,
    className,
}: {
    title: string;
    titleIcon?: ReactNode;
    children: ReactNode;
    className?: string;
}) => {
    const { classes, cx } = useSectionContainerStyles();
    return (
        <div className={cx(classes.container, className)}>
            <div className={classes.title}>
                {titleIcon && titleIcon}
                <Typography variant="h3">{title}</Typography>
            </div>
            <div className={classes.children}>{children}</div>
        </div>
    );
};
