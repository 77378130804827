import { useGetCropRotationQuery } from '@modules/results/api/summary.api';
import * as echarts from 'echarts';
import { useRef, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { CropRotationResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';
import { cropRotationGraphTheme } from '@modules/results/utils/theme/cropRotationGraphTheme';
import { adjustChartLegendHeight } from '@modules/results/utils/adjustChartLegendHeight';

export const useCropRotationGraph = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const {
        data: cropRotationGraphData,
        isLoading,
        isFetching,
        isError,
    } = useGetCropRotationQuery({
        farmSeasonId: currentSeasonId,
    });

    // Sort crop names by year and area, Y-2 is prioritized and those crop names should stil be displayed even when history is hidden
    const allCropNames = useMemo(() => {
        if (!cropRotationGraphData) return [];

        const uniqueCropNames = new Set<string>();
        const sortedYears = Object.keys(cropRotationGraphData).sort();
        const cropAreas: Record<string, { year: string; area: number }> = {};

        sortedYears.forEach((year) => {
            const yearData = cropRotationGraphData[year];
            if (Array.isArray(yearData)) {
                yearData.forEach((crop) => {
                    const cropName = t(crop.slug);
                    uniqueCropNames.add(cropName);

                    if (!cropAreas[cropName]) {
                        cropAreas[cropName] = {
                            year,
                            area: crop.area,
                        };
                    }
                });
            }
        });

        const cropNames = Array.from(uniqueCropNames);

        return cropNames.sort((a, b) => {
            const aInfo = cropAreas[a];
            const bInfo = cropAreas[b];

            if (aInfo.year !== bInfo.year) {
                return sortedYears.indexOf(aInfo.year) - sortedYears.indexOf(bInfo.year);
            }

            return bInfo.area - aInfo.area;
        });
    }, [cropRotationGraphData, t]);

    const [view, setView] = useState<GraphTypeT>(GraphTypeT.STACKED_BAR);
    const handleSwitchView = (view: GraphTypeT) => {
        setView(view);
    };

    const cropRotationGraphRef = useRef(null);

    const { data }: { data: CropRotationResponseT | undefined } = useToggleHistoryYears({
        data: cropRotationGraphData,
        showHistoryYears,
    });

    useEffect(() => {
        if (data && cropRotationGraphRef.current) {
            const cropRotationGraph = echarts.init(cropRotationGraphRef.current, cropRotationGraphTheme, {
                renderer: 'svg',
            });
            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            let totalArea = undefined;

            Object.keys(data).forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                const cropData = data[harvestYear];
                formattedData[translatedHarvestYear] = {};

                if (cropData && cropData.length > 0) {
                    totalArea = cropData[0].total_area;

                    allCropNames.forEach((cropName) => {
                        formattedData[translatedHarvestYear][cropName] = {
                            value: 0,
                            is_organic: false,
                        };
                    });

                    cropData.forEach((crop) => {
                        const cropName = t(crop.slug);
                        formattedData[translatedHarvestYear][cropName] = {
                            value: crop.area,
                            is_organic: crop.is_organic,
                        };
                    });
                }
            });

            const graphOptions = newGraphBuilder({
                data: formattedData,
                type: view,
                yLabel: t('results.summary.crop-rotation-graph.ylabel'),
                tooltipTotal: totalArea,
                tooltipUnit: t('constants.hectare-unit'),
                tooltipTitle: t('results.summary.crop-rotation-graph.title'),
            });
            cropRotationGraph.setOption(graphOptions);
            adjustChartLegendHeight(cropRotationGraph, 'crop-rotation-graph');
            return () => {
                cropRotationGraph.dispose();
            };
        }
    }, [data, t, view, allCropNames]);

    return {
        view,
        handleSwitchView,
        cropRotationGraphRef,
        isLoading: isLoading || isFetching,
        isError,
        t,
    };
};
