import { useGetMineralFertilisationNutrientsQuery } from '@modules/results/api/summary.api';
import * as echarts from 'echarts';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { MineralFertilisationNutrientsResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';
import { mineralFertilisationNutrientsGraphTheme } from '@modules/results/utils/theme/mineralFertilisationNutrientsGraphTheme';

export const useMineralFertilisationNutrientsGraph = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const {
        data: mineralFertilisationNutrientsData,
        isLoading,
        isFetching,
        isError,
    } = useGetMineralFertilisationNutrientsQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: MineralFertilisationNutrientsResponseT | undefined } = useToggleHistoryYears({
        data: mineralFertilisationNutrientsData,
        showHistoryYears,
    });

    const mineralFertilisationRef = useRef(null);

    useEffect(() => {
        if (data && mineralFertilisationRef.current) {
            const mineralFertilisationNutrientsGraph = echarts.init(
                mineralFertilisationRef.current,
                mineralFertilisationNutrientsGraphTheme,
                {
                    renderer: 'svg',
                },
            );
            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            Object.keys(data).forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });

                const record = data[harvestYear];
                formattedData[translatedHarvestYear] = {
                    [t('constants.phosphorus')]: { value: record.total_phosphorus },
                    [t('constants.potassium')]: { value: record.total_potassium },
                    [t('constants.magnesium')]: { value: record.total_magnesium },
                    [t('constants.calcium')]: { value: record.total_calcium },
                    [t('constants.sulfur')]: { value: record.total_sulfur },
                };
            });

            const graphOptions = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.MULTI_BAR,
                yLabel: t('results.summary.mineral-fertilisation-nutrients-graph.ylabel'),
                tooltipTitle: t('results.summary.mineral-fertilisation-nutrients-graph.tooltip.title'),
                tooltipUnit: t('results.summary.mineral-fertilisation-nutrients-graph.tooltip.unit'),
            });
            mineralFertilisationNutrientsGraph.setOption(graphOptions);
            return () => {
                mineralFertilisationNutrientsGraph.dispose();
            };
        }
    }, [data, t]);

    return {
        mineralFertilisationRef,
        isLoading: isLoading || isFetching,
        isError,
        t,
    };
};
