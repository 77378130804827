import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { resultRoutesConfig } from '@modules/results/result.routes';
import { FARM_SEASON_STATUS } from '@shared/entities/season/season.types';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useResultsSummaryLogic = () => {
    const navigate = useNavigate();
    const { currentSeasonId } = useCurrentSeasonId();
    const [showHistoryYears, setShowHistoryYears] = useState(true);
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const pathToRedirectTo =
        nextStepToComplete && getRouteMatchingStep(nextStepToComplete?.slug)
            ? getRouteMatchingStep(nextStepToComplete?.slug)
            : encodingRoutesConfig.PREPARATION;
    const handleBackClicked = () => navigate(`/${currentSeasonId}/results/${resultRoutesConfig.EARNING}`);
    const handleContinueEncoding = () => navigate(`/${currentSeasonId}/${pathToRedirectTo}`);
    const handleStartEncoding = () => navigate(`/${currentSeasonId}/${encodingRoutesConfig.PREPARATION}`);

    const { baselineSeason, isLoading } = useBaselineFarmSeason();
    const isEncodingDone =
        baselineSeason?.status !== FARM_SEASON_STATUS.DATA_INPUT_IN_PROGRESS &&
        baselineSeason?.status !== FARM_SEASON_STATUS.DATA_INPUT_NOT_STARTED;
    const isEncodingStarted = baselineSeason?.status !== FARM_SEASON_STATUS.DATA_INPUT_NOT_STARTED;

    return {
        isLoading,
        handleBackClicked,
        handleContinueEncoding,
        handleStartEncoding,
        isEncodingDone,
        isEncodingStarted,
        showHistoryYears,
        setShowHistoryYears,
    };
};
