import { useGetOrganicFertiliserQuery } from '@modules/results/api/summary.api';
import * as echarts from 'echarts';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { FarmSeasonOrganicFertiliserResponseT, OrganicFertilisersDataT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';
import { organicFertilisationGraphTheme } from '@modules/results/utils/theme/organicFertilisationGraphTheme';
import { adjustChartLegendHeight } from '@modules/results/utils/adjustChartLegendHeight';

export const useOrganicFertilisationGraph = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const {
        data: organicFertilisationData,
        isLoading,
        isFetching,
        isError,
    } = useGetOrganicFertiliserQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: FarmSeasonOrganicFertiliserResponseT | undefined } = useToggleHistoryYears({
        data: organicFertilisationData,
        showHistoryYears,
    });

    const organicFertilisationRef = useRef(null);
    const hasNoData =
        !isLoading &&
        (!data ||
            Object.keys(data).every((year) => !data[year] || !Array.isArray(data[year]) || data[year].length === 0) ||
            isError);

    useEffect(() => {
        if (data && organicFertilisationRef.current) {
            const organicFertilisation = echarts.init(organicFertilisationRef.current, organicFertilisationGraphTheme, {
                renderer: 'svg',
            });
            const years = Object.keys(data).sort();
            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};

            years.forEach((year) => {
                const translatedYear = t('constants.harvest-year', { harvestYear: year });
                formattedData[translatedYear] = {};
            });

            const allFertilizers = new Set<string>();
            years.forEach((year) => {
                const yearData = data[year];
                if (Array.isArray(yearData)) {
                    yearData.forEach((fert) => allFertilizers.add(fert.slug));
                }
            });

            const fertilizerValues: Record<string, number> = {};
            allFertilizers.forEach((slug) => {
                fertilizerValues[slug] = 0;
                years.forEach((year) => {
                    const yearData = data[year];
                    if (Array.isArray(yearData)) {
                        const fert = yearData.find((f) => f.slug === slug);
                        if (fert) {
                            fertilizerValues[slug] += fert.value;
                        }
                    }
                });
            });

            const sortedFertilizers = Array.from(allFertilizers).sort(
                (a, b) => fertilizerValues[b] - fertilizerValues[a],
            );

            years.forEach((year) => {
                const translatedYear = t('constants.harvest-year', { harvestYear: year });
                const yearData = data[year];
                const yearMap: Record<string, OrganicFertilisersDataT> = {};

                if (Array.isArray(yearData)) {
                    yearData.forEach((fert) => {
                        yearMap[fert.slug] = fert;
                    });
                }

                sortedFertilizers.forEach((slug) => {
                    const translatedName = t(slug);
                    formattedData[translatedYear][translatedName] = {
                        value: yearMap[slug]?.value || 0,
                    };
                });
            });

            const graphOptions = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.MULTI_BAR,
                yLabel: t('results.summary.organic-fertilisation-graph.ylabel'),
                tooltipTitle: t('results.summary.organic-fertilisation-graph.tooltip.title'),
                tooltipUnit: 'T',
            });
            organicFertilisation.setOption(graphOptions);
            adjustChartLegendHeight(organicFertilisation, 'organic-fertilisation-graph');

            return () => {
                organicFertilisation.dispose();
            };
        }
    }, [data, t]);

    return {
        hasNoData,
        organicFertilisationRef,
        isLoading: isLoading || isFetching,
        t,
    };
};
