import { useGetLivestockDataQuery } from '@modules/results/api/summary.api';
import { GraphDataItemT, GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { newGraphBuilder } from '@modules/results/utils/GraphBuilder/newGraphBuilder';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { livestockResponseT } from '@modules/results/api/summary.types';
import { useToggleHistoryYears } from '@modules/results/hooks/useToggleHistoryYears';
import { livestockGraphTheme } from '@modules/results/utils/theme/livestockGraphTheme';

export const useLivestockGraphLogic = (showHistoryYears: boolean) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();

    const {
        data: livestockData,
        isLoading: isLoadingLivestock,
        isFetching: isFetchingLivestock,
        isError,
    } = useGetLivestockDataQuery({
        farmSeasonId: currentSeasonId,
    });

    const { data }: { data: livestockResponseT | undefined } = useToggleHistoryYears({
        data: livestockData,
        showHistoryYears,
    });

    const livestockGraphRef = useRef(null);

    const allLivestockSlugs = useMemo(() => {
        const uniqueLivestockSlugs = new Set<string>();
        if (!livestockData) return uniqueLivestockSlugs;

        Object.values(livestockData).forEach((yearLivestock) => {
            yearLivestock.forEach((livestock) => {
                uniqueLivestockSlugs.add(livestock.slug);
            });
        });

        return uniqueLivestockSlugs;
    }, [livestockData]);

    const sortedLivestockSlugs = useMemo(() => {
        if (!livestockData) return [];

        const totalQuantities: Record<string, number> = {};

        allLivestockSlugs.forEach((slug) => {
            totalQuantities[slug] = 0;
        });

        Object.values(livestockData).forEach((yearLivestock) => {
            yearLivestock.forEach((livestock) => {
                if (livestock.slug) {
                    totalQuantities[livestock.slug] += livestock.quantity;
                }
            });
        });

        return Array.from(allLivestockSlugs).sort((a, b) => totalQuantities[b] - totalQuantities[a]);
    }, [livestockData, allLivestockSlugs]);

    useEffect(() => {
        if (data && livestockGraphRef.current) {
            const livestockGraph = echarts.init(livestockGraphRef.current, livestockGraphTheme, {
                renderer: 'svg',
            });
            const formattedData: Record<string, Record<string, GraphDataItemT>> = {};
            const years = Object.keys(data).sort();

            years.forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                formattedData[translatedHarvestYear] = {};
            });

            years.forEach((harvestYear) => {
                const translatedHarvestYear = t('constants.harvest-year', { harvestYear });
                const yearLivestock = data[harvestYear];

                const livestockMap: Record<string, number> = {};
                yearLivestock.forEach((livestock) => {
                    if (livestock.slug) {
                        livestockMap[livestock.slug] = livestock.quantity;
                    }
                });

                sortedLivestockSlugs.forEach((slug) => {
                    const translatedSlug = t(slug);
                    formattedData[translatedHarvestYear][translatedSlug] = {
                        value: livestockMap[slug] || 0,
                    };
                });
            });

            const options = newGraphBuilder({
                data: formattedData,
                type: GraphTypeT.BAR_LINE,
                yLabel: t('results.summary.livestock-graph.ylabel'),
                tooltipTitle: t('results.summary.livestock-graph.tooltip.title'),
                showTooltipPercentage: allLivestockSlugs.size > 1,
                showTotal: false,
            });

            livestockGraph.setOption(options);

            return () => {
                livestockGraph.dispose();
            };
        }
    }, [allLivestockSlugs, sortedLivestockSlugs, data, t]);

    return {
        isError,
        livestockGraphRef,
        isLoading: isLoadingLivestock || isFetchingLivestock,
        t,
    };
};
