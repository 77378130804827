import { GraphContainer } from '@modules/results/components/newShared/GraphContainer/GraphContainer';
import { IconCropRotation } from '@soil-capital/ui-kit/icons';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { useFuelConsumptionGraphStyles } from './FuelConsumptionGraph.style';
import { useFuelConsumptionGraphLogic } from './FuelConsumptionGraph.logic';

export const FuelConsumptionGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { fuelConsumptionGraphRef, t, isLoading } = useFuelConsumptionGraphLogic(showHistoryYears);
    const { classes } = useFuelConsumptionGraphStyles();

    return (
        <GraphContainer
            title={t('results.summary.fuel-consumption-graph.title')}
            type={GraphTypeT.BAR_LINE}
            isLoading={isLoading}
            titleIcon={<IconCropRotation fontSize="large" className={classes.icon} />}
        >
            <div ref={fuelConsumptionGraphRef} className={classes.graphContainer}></div>
        </GraphContainer>
    );
};
