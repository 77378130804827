import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { useSwitchView } from './SwitchView.logic';
import { MenuItem, Select } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { IconAgroforestry, IconBarChart, IconLineChart, IconStackedBarChart } from '@soil-capital/ui-kit/icons';
import { useSwitchViewStyles } from './SwitchView.style';

export const SwitchView = ({
    view,
    handleSwitchView,
}: {
    view: GraphTypeT;
    handleSwitchView: (view: GraphTypeT) => void;
}) => {
    const { graphViewOptions, toggleGraphView } = useSwitchView({ view, handleSwitchView });
    const { classes } = useSwitchViewStyles();

    return (
        <Select
            value={view}
            onChange={toggleGraphView}
            className={classes.select}
            sx={{ width: '75px', maxHeight: '45px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            {graphViewOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} className={classes.container}>
                    {option.value === GraphTypeT.STACKED_BAR && (
                        <IconStackedBarChart sx={{ transform: 'translateY(3px)' }} />
                    )}
                    {option.value === GraphTypeT.MULTI_BAR && <IconBarChart sx={{ transform: 'translateY(3px)' }} />}
                    {option.value === GraphTypeT.BAR_LINE && <IconLineChart sx={{ transform: 'translateY(3px)' }} />}
                </MenuItem>
            ))}
        </Select>
    );
};
