import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCropRotationGraphStyles = makeStyles()((theme) => ({
    container: {
        width: '100%',
        height: '350px',
    },
    icon: {
        color: theme.palette.darkScale[700],
    },
}));
