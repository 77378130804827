import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useNoDataFoundStyles } from './NoDataFound.style';

export const NoDataFound = () => {
    const { t } = useTranslation();
    const { classes } = useNoDataFoundStyles();

    return (
        <div className={classes.container}>
            <img src="/assets/images/no_data.svg" className={classes.image} />
            <Typography>{t('results.summary.no-data')}</Typography>
        </div>
    );
};
