import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';
import { useOrganicFertilisationGraph } from './OrganicFertilisationGraph.logic';
import { useOrganicFertilisationGraphStyles } from './OrganicFertilisationGraph.style';

export const OrganicFertilisationGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { hasNoData, organicFertilisationRef, isLoading, t } = useOrganicFertilisationGraph(showHistoryYears);
    const { classes } = useOrganicFertilisationGraphStyles();

    return (
        <GraphContainer
            title={t('results.summary.organic-fertilisation-graph.title')}
            isLoading={isLoading}
            hasBorders={!hasNoData}
            type={GraphTypeT.STACKED_BAR}
            hasNoData={hasNoData}
        >
            <div ref={organicFertilisationRef} className={classes.container} id="organic-fertilisation-graph" />
        </GraphContainer>
    );
};
