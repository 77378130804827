import { GraphContainer } from '@modules/results/components/newShared/GraphContainer/GraphContainer';
import { useIrrigationGraphLogic } from './IrrigationGraph.logic';
import { useIrrigationGraphStyles } from './IrrigationGraph.style';
import { MenuItem, Select } from '@soil-capital/ui-kit/components';
import { GraphTypeT } from '@modules/results/utils/GraphBuilder/graphBuilder.types';

export const IrrigationGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { displayUnit, displayUnitOptions, handleSwitchDisplayUnit, irrigationGraphRef, t, isLoading, isError } =
        useIrrigationGraphLogic(showHistoryYears);
    const { classes } = useIrrigationGraphStyles();

    return (
        <GraphContainer
            title={t('results.summary.irrigation-graph.title')}
            hasBorders
            isLoading={isLoading}
            type={GraphTypeT.BAR_LINE}
            titleEnd={
                <Select
                    value={displayUnit.value}
                    onChange={handleSwitchDisplayUnit}
                    sx={{ width: '240px', maxHeight: '46px' }}
                >
                    {displayUnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            }
            hasNoData={isError}
        >
            <div ref={irrigationGraphRef} className={classes.graphContainer}></div>
        </GraphContainer>
    );
};
