import { IconCropRotation } from '@soil-capital/ui-kit/icons';
import { useCropRotationGraphStyles } from './CropRotationGraph.style';
import { GraphContainer } from '../../newShared/GraphContainer/GraphContainer';
import { useCropRotationGraph } from './CropRotationGraph.logic';
import { SwitchView } from '../../newShared/SwitchView/SwitchView';

export const CropRotationGraph = ({ showHistoryYears }: { showHistoryYears: boolean }) => {
    const { cropRotationGraphRef, isLoading, t, view, handleSwitchView, isError } =
        useCropRotationGraph(showHistoryYears);
    const { classes } = useCropRotationGraphStyles();

    return (
        <GraphContainer
            titleIcon={<IconCropRotation className={classes.icon} fontSize="large" />}
            switchViewSelect={<SwitchView view={view} handleSwitchView={handleSwitchView} />}
            title={t('results.summary.crop-rotation-graph.title')}
            isLoading={isLoading}
            type={view}
            hasNoData={isError}
        >
            <div ref={cropRotationGraphRef} className={classes.container} id="crop-rotation-graph" />
        </GraphContainer>
    );
};
